import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination, Paper, useTheme, useMediaQuery, Box, } from '@mui/material';
import ApiService from "../../../config/api"; // Import your API service
import Sidebar from "../components/Sidebar";
import AdminNavbar from "../components/AdminNavbar";
import { useLocation } from 'react-router-dom';


const AllJoinUsQueries = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const [applicants, setApplicants] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const location = useLocation(); // Get the current pathname

    useEffect(() => {
        // Fetch data from API
        ApiService.get('/user/applicants')
            .then((response) => {
                setApplicants(response?.data?.applicants);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }, []);

    // Sorting Functionality
    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    // Pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Render the sorted and paginated table rows
    const displayRows = stableSort(applicants, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((applicant) => (
            <TableRow key={applicant.id} sx={{
                borderBottom: '1px solid var(--bs-primary)',
                '& td': {
                    border: '1px solid var(--bs-primary)',
                }
            }}>
                <TableCell>{applicant.name}</TableCell>
                <TableCell>{applicant.email}</TableCell>
                <TableCell>{applicant.phone}</TableCell>
                <TableCell>{applicant.message}</TableCell>
                <TableCell>{new Date(applicant.createdAt).toLocaleDateString()}</TableCell>
            </TableRow>
        ));

    return (
        <Box
            display="grid"
            gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
            sx={{
                transition: "grid-template-columns 0.3s ease-in-out",
                overflow: "hidden",
                backgroundColor: 'var(--bs-secondary)', 
                minHeight: '100vh',
                overflowY: 'auto',

            }}
        >
            <Sidebar
                open={sidebarOpen}
                toggleDrawer={toggleDrawer}
                setOpen={setSidebarOpen}
            />
            <div>

                <AdminNavbar />

                <Paper sx={{ backgroundColor: 'var(--bs-secondary)', padding: '20px' }}> 
                    <TableContainer className="custom-table-container" sx={{ overflowX: 'auto', width: { xs: '80vw', sm: '100%' }, }}>
                        <Table sx={{ minWidth: 800 }}>
                            <TableHead sx={{
                                backgroundColor: 'var(--bs-primary)', 
                                '& th': {
                                    color: 'var(--bs-accent)', 
                                    border: '1px solid var(--bs-primary)',
                                },
                            }}>
                                <TableRow>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'name'}
                                            direction={orderBy === 'name' ? order : 'asc'}
                                            onClick={() => handleRequestSort('name')}
                                            sx={{
                                                color: 'var(--bs-accent)',
                                                '&.Mui-active': {           
                                                    color: 'var(--bs-accent)',
                                                },
                                                '&:hover': {               
                                                    color: 'var(--bs-accent)',
                                                    '& .MuiTableSortLabel-icon': {
                                                        color: 'var(--bs-accent) !important',
                                                    },
                                                },
                                                '& .MuiTableSortLabel-icon': {
                                                    color: 'var(--bs-accent) !important', 
                                                },
                                            }}
                                        >
                                            Name
                                        </TableSortLabel>
                                    </TableCell>

                                    <TableCell align="center">Email</TableCell>
                                    <TableCell align="center">Phone</TableCell>
                                    <TableCell align="center">Message</TableCell>
                                    <TableCell align="center">
                                        <TableSortLabel
                                            active={orderBy === 'date'}
                                            direction={orderBy === 'date' ? order : 'asc'}
                                            onClick={() => handleRequestSort('date')}
                                            sx={{
                                                color: 'var(--bs-accent)', 
                                                '&.Mui-active': {           
                                                    color: 'var(--bs-accent)',
                                                },
                                                '&:hover': {                
                                                    color: 'var(--bs-accent)',
                                                    '& .MuiTableSortLabel-icon': {
                                                        color: 'var(--bs-accent) !important', 
                                                    },
                                                },
                                                '& .MuiTableSortLabel-icon': { 
                                                    color: 'var(--bs-accent) !important',  
                                                },
                                            }}
                                        >
                                            Date
                                        </TableSortLabel>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody sx={{
                                backgroundColor: 'var(--bs-secondary)', 
                                color: 'var(--bs-primary)',
                                '& td': {
                                    color: 'var(--bs-primary)', 
                                    textAlign: 'center',
                                }
                            }}>
                                {displayRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={applicants.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            display: 'flex',         // Align elements using flexbox
                            justifyContent: 'space-between', // Space between rows per page and pagination controls
                            alignItems: 'center',    // Vertically center the content
                            padding: '0 16px',       // Adjust padding for better spacing
                            color: 'var(--bs-primary)', // Pagination text color
                            '.MuiTablePagination-actions': {  // Target the pagination arrows (actions)
                                justifyContent: 'flex-end',     // Align pagination controls to the right
                            },
                        }}
                    />

                </Paper>
            </div>

        </Box>

    );
};

export default AllJoinUsQueries;

import React, { useState, useEffect, useRef } from "react";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Grid,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Divider,
    Modal,
    IconButton,
    Snackbar,
    Alert,
    TextField,
    Autocomplete
} from "@mui/material";
import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import ApiService from "../../../config/api";
import { useMediaQuery, useTheme } from "@mui/material";
import Sidebar from "../components/Sidebar";
import AdminNavbar from "../components/AdminNavbar";
import BathroomIcon from "@mui/icons-material/Bathtub";
import BedroomIcon from "@mui/icons-material/Bed";
import AreaIcon from "@mui/icons-material/SquareFoot";
import { useNavigate } from "react-router-dom";
import "./AssignLeads.css";

const AssignPhoneLead = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const [agents, setAgents] = useState([]);
    const [queries, setQueries] = useState([]);
    const [assignedQueries, setAssignedQueries] = useState([]);
    const [selectedAgent, setSelectedAgent] = useState("");
    const [selectedQueries, setSelectedQueries] = useState([]);
    const [priority, setPriority] = useState("Warm");
    const [selectedPropertyDetails, setSelectedPropertyDetails] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredQueries, setFilteredQueries] = useState([]);
    const [statusFilter, setStatusFilter] = useState("");

    useEffect(() => {
        const filtered = assignedQueries.filter((query) => {
            const agentFirstName = query?.agent?.firstName?.toLowerCase() || "";
            const agentLastName = query?.agent?.lastName?.toLowerCase() || "";
            const comment = query?.comment?.toLowerCase() || "";
            const queryStatus = query.status || "";

            const search = searchQuery.toLowerCase();

            // Match search query and status filter
            const matchesSearch =
                agentFirstName.includes(search) ||
                agentLastName.includes(search) ||
                `${agentFirstName} ${agentLastName}`.includes(search) ||
                comment.includes(search);

            const matchesStatus = statusFilter
                ? queryStatus.toLowerCase() === statusFilter.toLowerCase()
                : true;

            return matchesSearch && matchesStatus;
        });
        setFilteredQueries(filtered);
    }, [searchQuery, statusFilter, assignedQueries]);

    useEffect(() => {
        const fetchAgents = async () => {
            try {
                const successResponse = await ApiService.getAll("admin/employees");
                setAgents(successResponse.data);
            } catch (error) {
                console.error("Error fetching agents:", error);
            }
        };

        const fetchQueries = async () => {
            try {
                const successResponse = await ApiService.getAll("admin/getInfo");

                if (Array.isArray(successResponse.data.data)) {
                    setQueries(successResponse.data.data);
                } else {
                    console.error(
                        "Expected an array for queries, but got:",
                        successResponse.data
                    );
                    setQueries([]);
                }
            } catch (error) {
                console.error("Error fetching queries:", error);
                setQueries([]);
            }
        };

        const fetchAssignedQueries = async () => {
            try {
                const successResponse = await ApiService.getAll(
                    "admin/getAll-assignedInfo"
                );
                const assignedInfos = successResponse.data?.assignedInfos || [];
                if (Array.isArray(assignedInfos)) {
                    setAssignedQueries(assignedInfos);
                } else {
                    setAssignedQueries([]);
                }
            } catch (error) {
                setAssignedQueries([]);
            }
        };


        fetchAssignedQueries();
        fetchAgents();
        fetchQueries();
    }, []);

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSubmit = async () => {
        const payload = {
            agentId: selectedAgent,
            infoId: selectedQueries.map((query) => query._id),
            priority,
        };

        try {
            const response = await ApiService.post("admin/assignInfoToAgent", payload);
            console.log("kha", response)
            showSnackbar("Property lead assigned successfully!", "success");

            setSelectedAgent("");
            setSelectedQueries([]);
            setPriority("Warm");
        } catch (error) {
            showSnackbar("Error assigning property lead.", "error");
        }
    };

    const handleChange = (event) => {
        const { value } = event.target;

        const selectedQueryObjects = queries.filter((query) =>
            value.includes(query._id)
        );

        setSelectedQueries(selectedQueryObjects);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedPropertyDetails(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Box
            display="grid"
            gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
            sx={{
                transition: "grid-template-columns 0.3s ease-in-out",
                overflow: "hidden",
                backgroundColor: "var(--bs-secondary)",
            }}
        >
            <Sidebar
                open={sidebarOpen}
                toggleDrawer={toggleDrawer}
                setOpen={setSidebarOpen}
            />
            <div>
                <AdminNavbar />

                <Box sx={{ p: 1, px: isSmallScreen ? 1 : 2 }}>
                    <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        gap={2}
                        sx={{ paddingBottom: '8px' }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "transparent",
                                borderColor: "var(--bs-primar)",
                                width: '15%',
                                color: "var(--bs-primary)",
                                "&:hover": {
                                    backgroundColor: "var(--bs-accent)",
                                },
                                alignItems: "center",
                            }}
                            onClick={() => navigate("/admin/assignLeads")}
                        >
                            Website Leads
                        </Button>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: "var(--bs-accent)",
                                borderColor: "var(--bs-accent)",
                                width: '15%',
                                color: "var(--bs-primary)",
                                "&:hover": {
                                    backgroundColor: "var(--bs-accent)",
                                },
                                alignItems: "center",
                            }}
                            onClick={() => navigate("/admin/assignLeads/AssignPhoneLead")}>
                            Phone Leads

                        </Button>
                    </Box>
                    {/* Assign Lead Form */}
                    <div
                        className="card p-2 mb-2"
                        style={{
                            backgroundColor: "var(--bs-primary)",
                            color: "var(--bs-accent)",
                            border: "2px solid var(--bs-accent)",
                        }}
                    >
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                Assign Phone Lead to Agent
                            </Typography>
                            <Divider sx={{ my: 1 }} />

                            <div
                                className="bg-light p-2"
                                style={{ backgroundColor: "var(--bs-primary)!important" }}
                            >
                                <Grid container spacing={2}>
                                    {/* Select Agent  by Khalid */}

                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            fullWidth
                                            options={agents}
                                            getOptionLabel={(option) =>
                                                option ? `${option.firstName} ${option.lastName}` : ""
                                            } value={selectedAgent}
                                            onChange={(event, newValue) => setSelectedAgent(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Select Agent"
                                                    InputLabelProps={{
                                                        style: { color: "var(--bs-accent)" },
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { borderColor: "var(--bs-accent)", color: "var(--bs-accent)" },
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "var(--bs-accent)",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "var(--bs-accent)",
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* Select Queries */}
                                    <Grid item xs={12} sm={4}>
                                        <Autocomplete
                                            multiple
                                            fullWidth
                                            options={queries}
                                            getOptionLabel={(option) =>
                                                option.createdBy
                                                    ? `${option.createdBy.firstName} ${option.createdBy.lastName}`
                                                    : "Unknown"
                                            }
                                            value={selectedQueries}
                                            onChange={(event, newValue) => setSelectedQueries(newValue)}
                                            renderOption={(props, option, { selected }) => (
                                                <li {...props}>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlank fontSize="small" />}
                                                        checkedIcon={<CheckBox fontSize="small" />}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.createdBy
                                                        ? `${option.createdBy.firstName} ${option.createdBy.lastName}`
                                                        : "Unknown"}
                                                </li>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Select Leads"
                                                    placeholder="Select Leads"
                                                    InputLabelProps={{
                                                        style: { color: "var(--bs-accent)" },
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { borderColor: "var(--bs-accent)", color: "var(--bs-accent)" },
                                                    }}
                                                    sx={{
                                                        "& .MuiOutlinedInput-root": {
                                                            "& fieldset": {
                                                                borderColor: "var(--bs-accent)",
                                                            },
                                                            "&:hover fieldset": {
                                                                borderColor: "var(--bs-accent)",
                                                            },
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </Grid>


                                    <Grid item xs={12} sm={4}>
                                        <FormControl
                                            fullWidth
                                            sx={{ borderColor: " var(--bs-accent)" }}
                                        >
                                            <InputLabel
                                                sx={{ color: "var(--bs-accent)" }}
                                            >
                                                Select Priority
                                            </InputLabel>
                                            <Select
                                                labelId="priority-select-label"
                                                value={priority}
                                                onChange={(e) => setPriority(e.target.value)}
                                                label="Select Priority"
                                                fullWidth
                                                sx={{
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": {
                                                            borderColor: "var(--bs-accent)",
                                                        },
                                                        "&:hover fieldset": {
                                                            borderColor: "var(--bs-accent)",
                                                        },
                                                    },
                                                    "& .MuiSelect-icon": {
                                                        color: "var(--bs-accent)",
                                                    },
                                                    color: "var(--bs-accent)",
                                                }}
                                            >
                                                <MenuItem value="Hot">Hot</MenuItem>
                                                <MenuItem value="Warm">Warm</MenuItem>
                                                <MenuItem value="Cold">Cold</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>{" "}
                            </div>

                            <Box sx={{ mt: 1, ml: 1, mb: 1 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        backgroundColor: "var(--bs-accent)",
                                        borderColor: "var(--bs-accent)",
                                        color: "#fff",
                                        "&:hover": {
                                            backgroundColor: "var(--bs-accent)",
                                        },
                                        alignItems: "center",
                                    }}
                                    onClick={handleSubmit}
                                >
                                    Assign Lead
                                </Button>
                            </Box>
                        </Box>
                    </div>

                    {/* Display List of Assigned Queries by khalid 31/Dec/2024 */}

                    <>
                        <h5>List of Assigned Leads</h5>
                        <Box sx={{ mt: 2, ml: 1, mb: 1, display: "flex", alignItems: "center" }}>
                            <TextField
                                label="Search by name and comment..."
                                variant="outlined"
                                size="small"
                                sx={{ mr: 2, width: "400px" }}
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />

                            <FormControl sx={{ mr: 2, minWidth: 300 }} size="small">
                                <InputLabel>Status</InputLabel>
                                <Select label="Status"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}>
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    <MenuItem value="Contacted">Contacted</MenuItem>
                                    <MenuItem value="Closed">Closed</MenuItem>
                                    <MenuItem value="Site visited">Site visited</MenuItem>
                                    <MenuItem value="Hold">Hold</MenuItem>
                                    <MenuItem value="Failed">Failed</MenuItem>
                                    <MenuItem value="Pending">Pending</MenuItem>
                                    <MenuItem value="Not interested">Not interested</MenuItem>
                                    <MenuItem value="Interested">Interested</MenuItem>
                                </Select>
                            </FormControl>


                            {/* <FormControl sx={{ mr: 2, minWidth: 200 }} size="small">
                                <InputLabel>Priority</InputLabel>
                                <Select
                                    label="Priority"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    <MenuItem value="High">Hot</MenuItem>
                                    <MenuItem value="Medium">Warm</MenuItem>
                                    <MenuItem value="Low">Cold</MenuItem>
                                </Select>
                            </FormControl> */}
                        </Box>
                    </>

                    <table
                        striped
                        bordered
                        hover
                        responsive
                        className="table table-striped"
                        aria-label="assigned queries table"
                    >
                        <thead
                            style={{
                                color: "var(--bs-accent) !important",
                                backgroundColor: "var(--bs-primary)!important",
                                borderColor: "var(--bs-primary)!important",
                            }}
                        >
                            <tr className="aestyle">
                                <th
                                    style={{
                                        color: "var(--bs-accent) !important",
                                        backgroundColor: "var(--bs-primary)!important",
                                        borderColor: "var(--bs-primary)!important",
                                        textAlign: "center",
                                    }}
                                >
                                    Assigned to
                                </th>
                                <th
                                    style={{
                                        color: "var(--bs-accent) !important",
                                        backgroundColor: "var(--bs-primary)!important",
                                        borderColor: "var(--bs-primary)!important",
                                        textAlign: "center",
                                    }}
                                >
                                    Priority
                                </th>
                                <th
                                    style={{
                                        color: "var(--bs-accent) !important",
                                        backgroundColor: "var(--bs-primary)!important",
                                        borderColor: "var(--bs-primary)!important",
                                        textAlign: "center",
                                    }}
                                >
                                    Assign Date
                                </th>
                                <th
                                    style={{
                                        color: "var(--bs-accent) !important",
                                        backgroundColor: "var(--bs-primary)!important",
                                        borderColor: "var(--bs-primary)!important",
                                        textAlign: "center",
                                    }}
                                >
                                    Budget
                                </th>
                                {/* New columns for Status and Comment by khalid 31/Dec/2024 */}
                                <th
                                    style={{
                                        color: "var(--bs-accent) !important",
                                        backgroundColor: "var(--bs-primary)!important",
                                        borderColor: "var(--bs-primary)!important",
                                        textAlign: "center",
                                    }}
                                >
                                    Status
                                </th>
                                <th
                                    style={{
                                        color: "var(--bs-accent) !important",
                                        backgroundColor: "var(--bs-primary)!important",
                                        borderColor: "var(--bs-primary)!important",
                                        textAlign: "center",
                                        width: "200px",
                                    }}
                                >
                                    Comment
                                </th>
                                {/* Moved Property Query to last by khalid 31/Dec/2024 */}

                            </tr>
                        </thead>

                        <tbody>
                            {filteredQueries
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((query) => (
                                    <tr key={query._id} className="aestyle">
                                        <td
                                            className="aestyle"
                                            style={{
                                                border: "1px solid var(--bs-primary)!important",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                textTransform: "capitalize",
                                                fontSize: "1.1rem",
                                            }}
                                        >
                                            {query?.agent?.firstName} {query?.agent?.lastName}
                                        </td>
                                        <td
                                            className="aestyle"
                                            style={{
                                                border: "1px solid var(--bs-primary)!important",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                fontSize: "1.05rem",
                                            }}
                                        >
                                            {query.priority || "N/A"}
                                        </td>
                                        <td
                                            className="aestyle"
                                            style={{
                                                border: "1px solid var(--bs-primary)!important",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                fontSize: "1.05rem",
                                            }}
                                        >
                                            {new Date(query.createdAt).toLocaleDateString() || "N/A"}
                                        </td>
                                        <td
                                            className="aestyle"
                                            style={{
                                                border: "1px solid var(--bs-primary)!important",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                fontSize: "1.05rem",
                                            }}
                                        >
                                            {query?.infoId?.[0]?.budget || "N/A"}
                                        </td>
                                        <td
                                            className="aestyle"
                                            style={{
                                                border: "1px solid var(--bs-primary)!important",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                fontSize: "1.05rem",
                                            }}
                                        >
                                            {query.status || "Pending"}
                                        </td>
                                        <td
                                            className="aestyle"
                                            style={{
                                                border: "1px solid var(--bs-primary)!important",
                                                textAlign: "center",
                                                verticalAlign: "middle",
                                                width: "230px",
                                                fontSize: "1.05rem",
                                            }}
                                        >
                                            {query.comment || "No comment yet"}
                                        </td>
                                    </tr>
                                ))}
                        </tbody>

                    </table>


                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <label className="me-2">Rows per page:</label>
                            <select
                                value={rowsPerPage}
                                onChange={handleChangeRowsPerPage}
                                className="form-select form-select-sm d-inline w-auto"
                            >
                                <option value={5}>5</option>
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={20}>20</option>
                                <option value={25}>25</option>
                            </select>
                        </div>
                        <div>
                            <button
                                className="btn btn-sm btn-secondary"
                                style={{
                                    backgroundColor: "var(--bs-primary)!important",
                                    color: "var(--bs-accent)!important",
                                }}
                                onClick={(e) => handleChangePage(e, page - 1)}
                                disabled={page === 0}
                            >
                                Previous
                            </button>
                            <span className="mx-2">Page {page + 1}</span>
                            <button
                                style={{
                                    backgroundColor: "var(--bs-primary)!important",
                                    color: "var(--bs-accent)!important",
                                }}
                                className="btn btn-sm btn-secondary"
                                onClick={(e) => handleChangePage(e, page + 1)}
                                disabled={
                                    page >= Math.ceil(assignedQueries.length / rowsPerPage) - 1
                                }
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </Box>
            </div>

            <Modal open={showModal} onClose={handleCloseModal}>
                <Box
                    sx={{
                        p: 4,
                        backgroundColor: "var(--bs-secondary)",
                        margin: "auto",
                        width: "90vw",
                        height: "auto",
                        maxWidth: "600px",
                        borderRadius: "8px",
                        boxShadow: 24,
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "95vh",
                    }}
                >
                    <IconButton
                        onClick={handleCloseModal}
                        sx={{ position: "absolute", top: 3, right: 3 }}
                    >
                        <CloseIcon style={{ color: "var(--bs-primary)" }} />
                    </IconButton>
                    {selectedPropertyDetails ? (
                        <div>
                            <div
                                id="propertyCarousel"
                                className="carousel slide"
                                data-bs-ride="carousel"
                            >
                                <div className="carousel-indicators">
                                    {selectedPropertyDetails.images.map((_, index) => (
                                        <button
                                            type="button"
                                            key={index}
                                            data-bs-target="#propertyCarousel"
                                            data-bs-slide-to={index}
                                            className={index === 0 ? "active" : ""}
                                            aria-current={index === 0 ? "true" : "false"}
                                            aria-label={`Slide ${index + 1}`}
                                        ></button>
                                    ))}
                                </div>
                                <div className="carousel-inner">
                                    {selectedPropertyDetails.images.map((image, index) => (
                                        <div
                                            key={index}
                                            className={`carousel-item ${index === 0 ? "active" : ""}`}
                                        >
                                            <img
                                                src={image}
                                                className="d-block w-100"
                                                alt={`Property ${index + 1}`}
                                                style={{
                                                    height: "50vh",
                                                    objectFit: "cover", // Maintain aspect ratio by khalid 31/Dec/2024
                                                    width: "100%",
                                                    borderRadius: "4px",
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <button
                                    className="carousel-control-prev"
                                    type="button"
                                    data-bs-target="#propertyCarousel"
                                    data-bs-slide="prev"
                                >
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button
                                    className="carousel-control-next"
                                    type="button"
                                    data-bs-target="#propertyCarousel"
                                    data-bs-slide="next"
                                >
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "10px",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    sx={{ color: "var(--bs-primary)" }}
                                >
                                    {selectedPropertyDetails.title}
                                </Typography>
                                <Typography variant="h6" sx={{ color: "var(--bs-primary)" }}>
                                    ₹ {selectedPropertyDetails.price}
                                </Typography>
                            </div>

                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "var(--bs-primary)",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <BathroomIcon sx={{ marginRight: 1 }} />
                                    Bathroom: {selectedPropertyDetails.numberOfBathrooms}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "var(--bs-primary)",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <BedroomIcon sx={{ marginRight: 1 }} />
                                    Bedroom: {selectedPropertyDetails.numberOfBedrooms}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: "var(--bs-primary)",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <AreaIcon sx={{ marginRight: 1 }} />
                                    Area: {selectedPropertyDetails.area}
                                </Typography>
                            </div>
                            <br />
                            <Typography
                                variant="body1"
                                sx={{
                                    color: "var(--bs-primary)",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                {selectedPropertyDetails.description}
                            </Typography>
                        </div>
                    ) : (
                        <Typography>Loading...</Typography>
                    )}
                </Box>
            </Modal>


            {/* Snackbar for Notifications by khalid 31/Dec/2024 */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarSeverity}
                    sx={{ width: "100%" }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AssignPhoneLead;

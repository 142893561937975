import React, { useState } from "react";
import "./OurStory.css";
import Navbar from "../../../../pages/webpage/components/navbar/Navbar";
import Footer from "../../components/footer/Footer";

const OurStory = () => {
  const [activeTab, setActiveTab] = useState("Rent");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Navbar/>

      <div className="pagecolor mt-3">
        <div className="heroes min-vh-100 d-flex align-items-center">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-12 col-md-12 mb-3 d-flex justify-content-center">
                    <img
                      src="../../assets/images/logo/logoWithTagline.png"
                      alt="Top Image"
                      className="img-fluid custom-image "
                      style={{
                        width: "600px",
                        height: "auto",
                        borderRadius: "30px",
                        marginTop: "50px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 hero-text-about d-flex justify-content-center align-items-center">
                <div>
                  <h3
                    className="m-4"
                    style={{
                      color: "var(--bs-accent)",
                      fontFamily: "Bahnschrift SemiBold",
                      fontWeight: "bold",
                    }}
                  >
                    Our Story
                  </h3>
                  <p
                    className="contant"
                    style={{
                      color: " var(--bs-secondary)",
                      fontSize: "18px",
                    }}
                  >
                 In the heart of Bhopal, where heritage meets modern aspirations, three visionaries came together to redefine real estate sales and marketing—Mr. Suhail Ahmed, Engineer Siraj Khan, and Architect Saad Shahaab. Each brought a wealth of experience and a shared commitment to excellence, leading to the creation of <span style={{ fontWeight: 'bold' }}> *Eleganza Estate Solutions* </span>.  
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="container reviews">

          {/* Our Founders Section */}
          <div className="row justify-content-center">
            <h3
              className="contantheading"
              style={{
                color: "var(--bs-accent)",
                fontFamily: "Bahnschrift, sans-serif",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
              Our Founders
            </h3>
            <p
              className="text-center contant"
              style={{
                color: "var(--bs-secondary)",
                fontSize: "1rem",
                // marginBottom: "2rem", 
              }}
            >
              Eleganza was established by three visionaries who share a passion for innovation and excellence in real estate:
            </p>

            <div className="col-lg-4 col-md-6 mb-4">
              <div
                className="card shadow-lg border-0"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "20px",
                  transition: "transform 0.3s ease",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    height: "150px", 
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    backgroundColor: '#F4EBD0',
                    borderRadius: '20px'
                  }}
                >
                  <h5 className="card-title" style={{ fontWeight: "700", fontSize: "1.25rem" }}>
                    Siraj Khan
                  </h5>
                  <p
                    className="card-text1"
                    style={{
                      fontSize: ".8rem",
                      color: "#6B7280",
                      lineHeight: "1.4",
                      overflow: "visible",
                      marginTop: 5,
                    }}
                  >
                    With an M.Tech in Construction Technology Management, Siraj is the founder of Ideal Construction and Estetico Architecture Studio, bringing expertise in construction and project management to Eleganza.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div
                className="card shadow-lg border-0"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "20px",
                  transition: "transform 0.3s ease",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    height: "150px", 
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    backgroundColor: '#F4EBD0',
                    borderRadius: '20px'
                  }}
                >
                  <h5 className="card-title" style={{ fontWeight: "700", fontSize: "1.25rem" }}>
                    Saad Shahaab
                  </h5>
                  <p
                    className="card-text1"
                    style={{
                      fontSize: ".8rem",
                      color: "#6B7280",
                      lineHeight: "1.4",
                      wordWrap: "break-word",
                      maxHeight: "none",
                      overflow: "visible",
                      marginTop: 5
                    }}
                  >
                    A Bachelor of Architecture graduate from NIT Bhopal and founder of Estetico Architecture Studio, Saad ensures each property embodies superior design and functionality.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 mb-4">
              <div
                className="card shadow-lg border-0"
                style={{
                  width: "100%",
                  textAlign: "center",
                  borderRadius: "20px",
                  transition: "transform 0.3s ease",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    height: "150px", // Set a fixed height
                    display: "flex",
                    flexDirection: "column",
                    padding: "1rem",
                    backgroundColor: '#F4EBD0',
                    borderRadius: '20px'
                  }}
                >
                  <h5 className="card-title" style={{ fontWeight: "700", fontSize: "1.25rem" }}>
                    Suhail Ahmad
                  </h5>
                  <p
                    className="card-text1"
                    style={{
                      fontSize: ".8rem",
                      color: "#6B7280",
                      lineHeight: "1.4",
                      wordWrap: "break-word",
                      maxHeight: "none",
                      overflow: "visible",
                      marginTop: 5,
                    }}
                  >
                    A seasoned entrepreneur and a well-known name in Bhopal’s real estate market, Suhail’s extensive network and market knowledge are the backbone of our success.
                  </p>
                </div>
              </div>
            </div>
            <p
              className="text-center contant"
              style={{
                color: "var(--bs-secondary)",
                fontSize: "1rem",
                lineHeight: "1.8",
                marginTop: "2rem", // Spacing above the text
                marginBottom: "3rem", // Spacing below the paragraph
              }}
            >
              Together, they recognized an opportunity to offer premium properties without the burden of high brokerage fees. Eleganza was their solution: a platform providing exclusive, high-end properties with no hidden costs, ensuring a seamless and transparent experience for buyers.
            </p>
          </div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 hero-text-about" style={{ alignContent: "center" }}>
                <div
                  style={{
                    color: "var(--bs-secondary)",
                    fontSize: "16px",
                  }}
                >
                  <h5 style={{ fontWeight: "bold", marginTop: "20px", color: "var(--bs-accent)" }}>The Mission </h5>
                  <p style={{ fontSize: '16px' }}>
                  Eleganza Estate Solutions was born out of a simple idea: to create a real estate sales and marketing company that doesn’t just sell properties but delivers dreams. Leveraging their combined expertise in development, construction, and design, the founders aimed to make the real estate process seamless, transparent, and elegant—true to the company’s name .</p>

                  <h5 style={{ fontWeight: "bold", marginTop: "20px", color: "var(--bs-accent)" }}>The Present </h5>
                  <p style={{ fontSize: '16px' }}>
                  Currently headquartered in Bhopal, Eleganza Estate Solutions has quickly earned a reputation for its professional approach and customer-centric services. Whether it’s luxurious residential properties, turnkey projects, or investment opportunities, Eleganza ensures each transaction is a smooth and satisfying experience for clients .<br/>
                  Through innovative marketing strategies, expert consultation, and an unmatched understanding of the local real estate landscape, the company continues to set new standards in the industry.  
                  </p>

                  <h5 style={{ fontWeight: "bold", marginTop: "20px", color: "var(--bs-accent)" }}>The Future</h5>
                  <p style={{ fontSize: '16px' }}>
                  With a solid foundation laid by its visionary founders, Eleganza Estate Solutions is poised for growth, aiming to expand its services beyond Bhopal. The team envisions a future where the company becomes synonymous with trust, sophistication, and excellence in real estate marketing and sales. <br/>
                  Eleganza Estate Solutions isn’t just a company—it’s a legacy of collaboration, expertise, and a shared passion for transforming the real estate journey into something truly elegant . </p>

                  <blockquote className="text-center" style={{ fontSize: '16px', fontStyle: 'italic' ,marginTop:'30px'}}>
                    "More than a real estate firm, it’s your gateway to timeless elegance and timeless investment."
                  </blockquote>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Footer />
    </>
  );
};

export default OurStory;
import React, { useState, useEffect } from 'react';
import { List, ListItemText, IconButton, Drawer, Tooltip, useMediaQuery, useTheme, ListItemButton, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import LogoutConfirmationModal from '../../../login/LogoutConfirmationModal'; 

const EmployeeSidebar = ({ open, toggleDrawer, setOpen }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (isSmallScreen) {
            setOpen(false);
        }
    }, [isSmallScreen, setOpen]);

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload()
    };


    const handleOpenModal = () => {
        setModalOpen(true);  // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false);  // Close the modal
    };

    const handleConfirmLogout = () => {
        handleLogout();
        handleCloseModal(); 
    };


    return (
        <Drawer
            variant="permanent"
            open={open}
            sx={{
                width: open ? '200px' : '60px',
                flexShrink: 0,
                whiteSpace: 'nowrap',
                '& .MuiDrawer-paper': {
                    width: open ? '200px' : '60px',
                    transition: 'width 0.3s ease-in-out',
                    overflowX: 'hidden',
                    backgroundColor: 'var(--bs-primary)',
                    color: 'var(--bs-accent) ',
                },
            }}
        >
            <div style={{ width: open ? '200px' : '60px', height: '100vh', transition: 'width 0.3s', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <IconButton onClick={toggleDrawer} style={{ color: 'var(--bs-accent)', margin: '10px' }}>
                        <MenuIcon />
                    </IconButton>

                    <List>

                    <Tooltip title="Self Property" placement="right">
                            <ListItemButton component={Link} to='/employee/employeePropertyList'>
                                <FormatListBulletedIcon style={{ }} />
                                {open && <ListItemText primary="Self Property" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip title="Add Property" placement="right">
                            <ListItemButton component={Link} to='/employee/employeePropertyForm'>
                                <HomeIcon style={{ }} />
                                {open && <ListItemText primary="Add Property" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip title="All Properties" placement="right">
                            <ListItemButton component={Link} to='/employee/EmployeeAllProperty'>
                            <HomeIcon style={{ }} />
                            {open && <ListItemText primary="All Properties" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip title="Assigned Leads" placement="right">
                            <ListItemButton component={Link} to='/employee/assignedLeads'>
                            <QueryStatsIcon style={{ }} />
                            {open && <ListItemText primary="Assigned Leads" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                        {/* <Tooltip title="Create Leads" placement="right">
                            <ListItemButton component={Link} to='/employee/assignedLeads'>
                            <PersonAddIcon style={{ }} />
                            {open && <ListItemText primary="Create Leads" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip> */}

                   


                    </List>
                </div>











                <Box sx={{ padding: '10px' }}>
                    {open ? (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'var(--bs-accent)',
                                '&:hover': { backgroundColor: 'var(--bs-accent)' }
                            }}
                            onClick={handleOpenModal}  // Open the modal on click
                        >
                            Logout
                        </Button>
                    ) : (
                        <Tooltip title="Logout" placement="right">
                            <IconButton sx={{ color: 'var(--bs-accent)' }} onClick={handleOpenModal}>
                                <PowerSettingsNewIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    {/* Logout Confirmation Modal */}
                    <LogoutConfirmationModal
                        open={isModalOpen}
                        handleClose={handleCloseModal}
                        handleConfirmLogout={handleConfirmLogout}
                    />
                </Box>
            </div>
        </Drawer>
    );
};

export default EmployeeSidebar;

import React, { useState } from "react";
import "./Login.css";
import { loginService } from "../config/services";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import Typography from "@mui/material/Typography";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';

const Login = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const { setRole } = useAuth();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!termsAccepted) {
      setError("Please accept the terms and conditions.");
      return;
    }

    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    try {
      setLoading(true);
      const response = await loginService(email, password);
      localStorage.setItem("accessToken", response.meta.token);
      localStorage.setItem("role", response.meta.role);
      localStorage.setItem("user", JSON.stringify(response.meta.user));
      const role = localStorage.getItem("role");
      setRole(role);

      if (!response.error) {
        if (role === "superAdmin") {
          navigate("admin/superAdmin");
        } else if (role === "admin") {
          navigate("/admin/adminPropertyListing");
        } else if (role === "employee") {
          const isVerified = response.meta.user.verified;
          if (isVerified) {
            navigate("/employee/employeePropertyList");
          } else {
            navigate("/employeeOtpVerify");
          }
        } else if (role === "user") {
          navigate("/");
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };


  const handleTermsChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleTermsClick = (event) => {
    event.preventDefault();
    navigate("/termsAndConditions");
  };

  return (
    <>
      <div style={{ backgroundColor: "var(--bs-primary)" }}>
        <div className="container d-flex justify-content-center align-items-center min-vh-100">
          <div
            className="row border rounded-5 p-3  shadow box-area"
            style={{
              maxWidth: "1000px",
              backgroundColor: "var(--bs-secondary)",
              padding: "200px",
            }}
          >
            <div
              className="col-md-6 rounded-4 d-flex justify-content-center align-items-center flex-column left-box"
              style={{
                backgroundColor: " #122620",
                padding: "3rem",
              }}
            >
              <div className="featured-image mb-3">
                <img
                  src="../../assets/images/logo/logoWithTagline.png"
                  className="img-fluid"
                  style={{ width: "500px" }}
                  alt="Logo"
                  onClick={() => navigate(`/`)}
                />
              </div>
            </div>

            <div className="col-md-6 right-box" style={{ padding: "40px" }}>
              <div className="row align-items-center">
                <div className="header-text mb-4" style={{ color: "#122620" }}>
                  <h2>Hello, Again</h2>
                  <p>We are happy to have you back.</p>
                </div>


                <form onSubmit={handleSubmit}>
                  <div className="mb-3 position-relative">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email address"
                      required
                    />
                  </div>

                  <div className="mb-3 position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      required
                      style={{ paddingRight: "40px" }}
                    />
                    <IconButton
                      onClick={togglePasswordVisibility}
                      className="position-absolute"
                      style={{
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </div>

                  <div className="d-flex justify-content-end mb-2">
                    <div
                      style={{ color: "var(--bs-primary)", textDecoration: "underline", cursor: 'pointer' }}
                      onClick={() => navigate("/forgotPassword")}
                    >
                      Forgot Password?
                    </div>
                  </div>

                  {/* add loader by khalid  */}


                  <div className="form-check mb-3">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="termsCheck"
                      checked={termsAccepted}
                      onChange={handleTermsChange}
                      style={{ backgroundColor: 'var(--bs-primary)' }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="termsCheck"
                      style={{ color: "var(--bs-accent)" }}
                    >
                      I accept the{" "}
                      <span
                        onClick={handleTermsClick}
                        style={{ color: "var(--bs-primary)", cursor: "pointer", textDecoration: "underline" }}
                      >
                        terms and conditions
                      </span>
                    </label>
                    {error && (
                      <Typography color="error" variant="body2">
                        {error}
                      </Typography>
                    )}
                  </div>


                  <div className="input-group mb-3 d-flex justify-content-center mt-3">
                    <button
                      type="submit"
                      className="btn btn-lg w-50 fs-6 text-white"
                      style={{ backgroundColor: "#d6ab5e", color: "#F4EBD0" }}
                      disabled={loading} 
                    >
                      {loading ? (
                        <CircularProgress size={20} style={{ color: "#000" }} />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>

                  <div
                    className="text-center mt-3"
                    style={{ color: "var(--bs-primary)" }}
                  >
                    <Typography variant="body2">
                      Don't have an account?{" "}
                      <a
                        href="/userRegister"
                        style={{
                          color: "#d6ab5e",
                          textDecoration: "underline",
                        }}
                        onClick={() => navigate("/userRegister")}
                      >
                        Sign Up
                      </a>
                    </Typography>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Slider from "react-slick";
import "./PropertyDescription.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useParams } from "react-router-dom";
import LocalHotelOutlinedIcon from "@mui/icons-material/LocalHotelOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Navbar from "../components/navbar/Navbar";
import SquareFootIcon from "@mui/icons-material/SquareFoot";
import ApiService from "../../../config/api";
import Footer from "../components/footer/Footer";
import RoomIcon from "@mui/icons-material/Room";
import { Snackbar, Alert } from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PoolIcon from "@mui/icons-material/Pool"; 
import ParkingIcon from "@mui/icons-material/LocalParking"; 
import GardenIcon from "@mui/icons-material/LocalFlorist"; 
import ClubhouseIcon from "@mui/icons-material/MeetingRoom"; 
import PlaygroundIcon from "@mui/icons-material/ChildCare"; 
import SecurityIcon from "@mui/icons-material/Security"; 
import PowerBackupIcon from "@mui/icons-material/BatteryChargingFull"; 
import ElevatorIcon from "@mui/icons-material/Elevator"; 
import WifiIcon from "@mui/icons-material/Wifi"; 

import LocalMallIcon from "@mui/icons-material/LocalMall";
import HospitalIcon from "@mui/icons-material/LocalHospital"; 
import SchoolIcon from "@mui/icons-material/School"; 
import PublicTransportIcon from "@mui/icons-material/LocalTaxi"; 
import RestaurantIcon from "@mui/icons-material/Restaurant"; 
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SupermarketIcon from "@mui/icons-material/LocalGroceryStore";
import ParkIcon from "@mui/icons-material/Park"; 
import MovieIcon from "@mui/icons-material/LocalMovies"; 
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import CheckIcon from "@mui/icons-material/Check";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";

const PropertyDescription = () => {
  const callUs = () => {
    window.location.href = "tel:+918962508912";
  };

  const whatsappUs = () => {
    window.location.href = "https://wa.me/+918962508912";
  };

  const formSaved = localStorage.getItem("formSaved");

  const [activeComponent, setActiveComponent] = useState(null);
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const [property, setProperty] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    const getDataFromServer = async () => {
      try {
        const successResponse = await ApiService.get(`/user/getProperty/${id}`);
        const {
          meta: { property },
        } = successResponse.data;
        setProperty(property);
      } catch (error) {
        console.error(error);
      }
    };
    getDataFromServer();
  }, [id]);

  const validate = () => {
    let tempErrors = {};
    if (!name) tempErrors.name = "Name is required";
    if (!email) {
      tempErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      tempErrors.email = "Email is not valid";
    }
    if (!phone) tempErrors.phone = "Phone number is required";
    else if (!/^\d{10}$/.test(phone)) {
      tempErrors.phone = "Phone number must be 10 digits";
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) {
      setSnackbarMessage("Please fill in the required fields correctly.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const data = {
      propertyId: id,
      name,
      email,
      phone,
    };

    try {
      await ApiService.post("/user/query", data);
      setSnackbarMessage(
        "Your query has been sent successfully. Our agent will contact you shortly."
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      localStorage.setItem("formSaved", true);
      setName("");
      setEmail("");
      setPhone("");
    } catch (error) {
      setSnackbarMessage(
        "Error submitting your query. Please try again later."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleButtonClick = (component) => {
    setActiveComponent(component);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const SlideShow = () => {
    const carouselSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div className="slide-show">
        <button
          className="btn btn-secondary close-button-slider z-1"
          onClick={handleClose}
        >
          <CloseIcon />
        </button>
        <Slider {...carouselSettings}>
          {property?.images?.map((image, index) => (
            <div className="carousel-item" key={index}>

              <img
                src={image}
                className="d-block w-100 slide-image"
                alt={`Slide ${index + 1}`}
                style={{ objectFit: "contain", height: "550px" }}
              />
            </div>
          ))}
        </Slider>
      </div>
    );
  };

  return (
    <div className="property-background">
      <>
        <div className="">
          <Navbar />
          {/* amaan */}
          <div className="container   property-image-container  property-margin">
            <nav aria-label="breadcrumb mt-1">
              <ol className="breadcrumb mb-0">
                <li className="breadcrumb-item">
                  <Link to="/" style={{ color: "var(--bs-accent)" }}>
                    Home /
                  </Link>
                </li>
                <li className="breadcrumb-item  ">
                  <Link to="/buyPage" style={{ color: "var(--bs-accent)" }}>
                    Buy /
                  </Link>
                </li>
                <li
                  className="breadcrumb-item active"
                  aria-current="page"
                  style={{ color: "var(--bs-accent)" }}
                >
                  Property Description
                </li>
              </ol>
            </nav>

            <div className="row">
              <div className="col-lg-8 col-md-12 mb-4 mt-3">
                <div className="image-wrapper">
                  <img
                    src={property?.images?.[0]}
                    className="large-image img-fluid"
                    alt="Large"
                    style={{ borderRadius: "10PX" }}
                    onClick={() => handleButtonClick("slide")}
                  />
                  <div className="button-container d-flex">
                    <button
                      className="overlay-button"
                      onClick={() => handleButtonClick("slide")}
                    >
                      <i
                        className="bi bi-images"
                        style={{ marginRight: "10px" }}
                      ></i>
                      <span>{`1/${property?.images?.length} Photos`}</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6  mt-3 d-flex flex-column ">
                {property?.images?.slice(1, 3).map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    className="small-image img-fluid mb-3"
                    alt={`Small ${index + 1}`}
                    style={{ borderRadius: "10px" }}
                    onClick={() => handleButtonClick("slide")}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="container   d-flex justify-content-between">
          <div className="content">
            <h2 className="mt-3 description-head">{property?.title}</h2>
            <h6 className="description-head">
              <RoomIcon style={{ fontSize: 16 }} />
              {property?.city}
            </h6>
          </div>

          <div className="content ">
            <h2 className="card-title mt-3 mb-2 description-head">
              ₹ {property?.price?.toLocaleString()} /-
            </h2>
            <h6 className=" description-head">{property?.area} Sq Ft</h6>
          </div>
        </div>

        <div className="container mt-5">
          <div className="row">
            <div className="col-lg-8 col-md-12 mb-4">
              <div className="card property-card mb-4">
                <div className="card-body">
                  <h5 className="card-title  headingtwo ">Description</h5>
                  <hr />
                  <p className="  headingtwo ">{property?.description}</p>
                </div>
              </div>

              {/* Overview Section by Khalid 02/Jan/2025 */}

              <div className="card property-card mb-4">
                <div className="card-body">
                  <h5 className="card-title  headingtwo ">Features</h5>

                  <hr />
                  <div className="row text-center">
                    <div className="col-md-3 col-6 mb-3">
                      <small className="text-muted mt-2 d-block">
                        Property Type
                      </small>
                      <p className="mt-3">{property?.title}</p>
                    </div>
                    <div className="col-md-3 col-6 mb-3">
                      <small className=" headingtwo  mt-2 d-block">
                        {" "}
                        <LocalHotelOutlinedIcon className="me-2 headingtwo " />
                      </small>
                      <p className="mt-3  headingtwo ">
                        Bedrooms:
                        <strong className="ms-1  headingtwo ">
                          {property?.numberOfBedrooms}
                        </strong>
                      </p>
                    </div>
                    <div className="col-md-3 col-6 mb-3">
                      <small className=" description-head mt-2 d-block">
                        {" "}
                        <BathtubOutlinedIcon className="me-2  headingtwo " />
                      </small>
                      <p className="mt-3  headingtwo ">
                        Bathrooms:
                        <strong className="ms-1  headingtwo ">
                          {property?.numberOfBathrooms}
                        </strong>
                      </p>
                    </div>
                    <div className="col-md-3 col-6 mb-3">
                      <small className="headingtwo  mt-2 d-block">
                        {" "}
                        <SquareFootIcon className="me-2  headingtwo " />
                      </small>
                      <p className="mt-3  headingtwo ">
                        Area:
                        <strong className="ms-1  headingtwo ">
                          {property?.area} SqFt
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* -------------  by Khalid 02/Jan/2025  */}

              <div className="card property-card">
                <div className="d-flex">
                  <div className="col-6 p-0">
                    <div className="card-body amenities-padding">
                      <h5 className="card-title headingtwo">Amenities</h5>
                      <hr />                    

                      <ul className="headingtw p-0">
                        {property?.amenities?.map((feature, index) => {
                          let IconComponent = null;

                          switch (feature) {
                            case "Gym":
                              IconComponent = FitnessCenterIcon;
                              break;
                            case "Swimming Pool":
                              IconComponent = PoolIcon;
                              break;
                            case "Parking":
                              IconComponent = ParkingIcon;
                              break;
                            case "Garden":
                              IconComponent = GardenIcon;
                              break;
                            case "Clubhouse":
                              IconComponent = ClubhouseIcon;
                              break;
                            case "Playground":
                              IconComponent = PlaygroundIcon;
                              break;
                            case "24/7 Security":
                              IconComponent = SecurityIcon;
                              break;
                            case "Power Backup":
                              IconComponent = PowerBackupIcon;
                              break;
                            case "Elevator":
                              IconComponent = ElevatorIcon;
                              break;
                            case "Wi-Fi Connectivity":
                              IconComponent = WifiIcon;
                              break;
                            default:
                              IconComponent = CheckIcon;
                              break;
                          }

                          return (
                            <li
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "5px",
                              }}
                            >
                              <IconComponent style={{ marginRight: "8px" }} />
                              {feature}
                            </li>
                          );
                        })}
                      </ul>                    
                    </div>
                  </div>
                  <div className="col-6 p-0">
                    <div className="card-body amenities-padding">
                      <h5 className="card-title headingtwo">Near by Places </h5>
                      <hr />

                      <ul className="headingtwo p-0">
                        {property?.nearbyPlaces?.map((feature, index) => {
                          let IconComponent = null;

                          switch (feature) {
                            case "Shopping Mall":
                              IconComponent = LocalMallIcon;
                              break;
                            case "Hospital":
                              IconComponent = HospitalIcon;
                              break;
                            case "School":
                              IconComponent = SchoolIcon;
                              break;
                            case "Public Transport":
                              IconComponent = PublicTransportIcon;
                              break;
                            case "Restaurant":
                              IconComponent = RestaurantIcon;
                              break;
                            case "Bank":
                              IconComponent = AccountBalanceIcon;
                              break;
                            case "Supermarket":
                              IconComponent = SupermarketIcon;
                              break;
                            case "Park":
                              IconComponent = ParkIcon;
                              break;
                            case "Gym":
                              IconComponent = FitnessCenterIcon;
                              break;
                            case "Cinema":
                              IconComponent = MovieIcon;
                              break;
                            default:
                              IconComponent = LocationOnIcon;
                              break;
                          }
                          return (
                            <li
                              key={index}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: "5px",
                              }}
                            >
                              <IconComponent style={{ marginRight: "8px" }} />
                              {feature}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Section - Contact Form  by Khalid 02/Jan/2025 */}

            <div className="col-lg-4 col-md-12 mb-1">
              <div className="card property-card">
                <div className="card-body">
                  <div className="d-flex align-items-center mx-1 justify-content-center ">
                    <h3 className="mb-0">Get a quote</h3>
                  </div>

                  <div className="d-flex align-items-center justify-content-center">
                    <small className="text-muted mb-2">
                      <InfoOutlinedIcon
                        fontSize="small"
                        sx={{ marginRight: "3px" }}
                      />
                      Fill up a form to enable call & WhatsApp
                    </small>
                  </div>

                  <form onSubmit={handleSubmit}>
                    {formSaved ? (
                      <p className="mt-3 d-flex justify-content-center">
                        Thank You, we will contact you shortly or feel free to
                        call us.
                      </p>
                    ) : (
                      <>
                        <input
                          type="hidden"
                          name="propertyId"
                          value={property?.id || ""}
                        />
                        <div className="mb-3">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {errors.name && (
                            <small className="text-danger">{errors.name}</small>
                          )}
                        </div>
                        <div className="mb-3">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          {errors.phone && (
                            <small className="text-danger">
                              {errors.phone}
                            </small>
                          )}
                        </div>
                        <div className="mb-3">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email}
                            </small>
                          )}
                        </div>
                        <div className="d-flex mt-4">
                          <button
                            type="submit"
                            className="btn btn-property me-2 w-100 btn-hoverrr"
                          >
                            Get Quote
                          </button>
                        </div>
                      </>
                    )}

                    <div>
                      <div className="d-flex contact-call-btn mt-2">                     
                        <button
                          type="button"
                          disabled={!formSaved}
                          className="btn btn-property me-2 w-100 btn-hoverrr"
                          onClick={() => callUs()}
                        >
                          <LocalPhoneIcon /> Call Us
                        </button>

                        <button
                          type="button"
                          disabled={!formSaved}
                          className="btn btn-property me-2 w-100 btn-hoverrr"
                          onClick={() => whatsappUs()}
                        >
                          <WhatsAppIcon /> WhatsApp
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <h2 className="p-2 description-head">Location</h2>
              <p>{property?.location}</p>
              <iframe
                src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3665.6181912415755!2d77.39985317469487!3d23.256976979010204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c6821c2cdaa6f%3A0xf1573add2bea7bf!2s${property?.location}!5e0!3m2!1sen!2sin!4v1722776927160!5m2!1sen!2sin`}
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
              ></iframe>
            </div>
          </div>
        </div>
        <div className="bg-modal-container">
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Body
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0",
                width: "100%",
              }}
            >
              {activeComponent === "slide" && <SlideShow />}
            </Modal.Body>
          </Modal>
        </div>
        <Footer />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </>
    </div>
  );
};

export default PropertyDescription;

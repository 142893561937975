import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper, useMediaQuery, useTheme, Box
} from '@mui/material';
import ApiService from '../../../config/api'; // Assuming ApiService is correctly configured
import Sidebar from '../components/Sidebar';
import AdminNavbar from '../components/AdminNavbar';

const CustomerQuery = () => {
  const [queries, setQueries] = useState([]); // State to store queries
  const [total, setTotal] = useState(0); // Total number of queries
  const [page, setPage] = useState(0); // Current page (zero-based for TablePagination)
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page


  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };
  // Fetch customer queries from API with pagination
  const fetchQueries = (pageNumber) => {
    ApiService.getAll(`admin/getQueries?page=${pageNumber + 1}&limit=${rowsPerPage}`)
      .then((response) => {
        setQueries(response?.data?.data || []); // Update the queries with fetched data
        setTotal(response?.data?.total || 0); // Update total count
      })
      .catch((error) => {
        console.error('Error fetching queries:', error);
      });
  };

  // Fetch queries on component mount and when page/rowsPerPage changes
  useEffect(() => {
    fetchQueries(page);
  }, [page, rowsPerPage]);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
      sx={{
        transition: "grid-template-columns 0.3s ease-in-out",
        overflow: "hidden",
        backgroundColor: 'var(--bs-secondary)',
        minHeight: '100vh',
        overflowY: 'auto',

      }}
    >
      <Sidebar
        open={sidebarOpen}
        toggleDrawer={toggleDrawer}
        setOpen={setSidebarOpen}
      />
      <div>

        <AdminNavbar />
        <Paper sx={{ backgroundColor: 'var(--bs-secondary)', padding: '20px' }}>
          <TableContainer className="custom-table-container" sx={{ overflowX: 'auto', width: { xs: '80vw', sm: '100%' }, }}>
            <Table sx={{ minWidth: 800 }}>
              <TableHead sx={{
                backgroundColor: 'var(--bs-primary)',
                '& th': {
                  color: 'var(--bs-accent)',
                  border: '1px solid var(--bs-primary)',
                },
              }}>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {queries.length === 0 ? (
                  <TableRow className='' style={{height:'50vh'}}>
                    <TableCell colSpan={4} align="center">
                      No queries available
                    </TableCell>
                  </TableRow>
                ) : (
                  queries.map((query) => (
                    <TableRow key={query._id}>
                      <TableCell>{query.name}</TableCell>
                      <TableCell>{query.email}</TableCell>
                      <TableCell>{query.phone}</TableCell>
                      <TableCell>{new Date(query.createdAt).toLocaleDateString()}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>

            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={total} // Total number of records
            rowsPerPage={rowsPerPage} // Number of rows per page
            page={page} // Current page
            onPageChange={handleChangePage} // Handle page change
            onRowsPerPageChange={handleChangeRowsPerPage} // Handle rows per page change
          />
        </Paper>
      </div>

    </Box>
  );
};

export default CustomerQuery;



import React, { useState, useEffect } from 'react';
import { List, ListItemText, IconButton, Drawer, Tooltip, useMediaQuery, useTheme, ListItemButton, Box, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GroupsIcon from '@mui/icons-material/Groups';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useLocation } from 'react-router-dom';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ApartmentIcon from '@mui/icons-material/Apartment';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import LogoutConfirmationModal from '../../../login/LogoutConfirmationModal';
const Sidebar = ({ open, toggleDrawer, setOpen }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();


    const [isModalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (isSmallScreen) {
            setOpen(false);
        }
    }, [isSmallScreen, setOpen]);

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload()
    };


    const handleOpenModal = () => {
        setModalOpen(true);  // Open the modal
    };

    const handleCloseModal = () => {
        setModalOpen(false);  // Close the modal
    };

    const handleConfirmLogout = () => {
        handleLogout();
        handleCloseModal();
    };

    return (
        <Drawer
            variant="permanent"
            open={open}
            sx={{
                width: open ? '200px' : '60px',
                flexShrink: 0,
                whiteSpace: 'nowrap',
                '& .MuiDrawer-paper': {
                    width: open ? '200px' : '60px',
                    transition: 'width 0.3s ease-in-out',
                    overflowX: 'hidden',
                    backgroundColor: 'var(--bs-primary)',
                    color: 'var(--bs-accent) ',
                },
            }}
        >
            <div style={{ width: open ? '200px' : '60px', height: '100vh', transition: 'width 0.3s', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <IconButton onClick={toggleDrawer} style={{ color: 'var(--bs-accent)', margin: '10px' }}>
                        <MenuIcon />
                    </IconButton>

                    <List>
                        <Tooltip title="Manage Property" placement="right">
                            <ListItemButton
                                component={Link}
                                to='/admin/adminPropertyListing'
                                selected={location.pathname === '/admin/adminPropertyListing'}
                                sx={{
                                    backgroundColor: location.pathname === '/admin/adminPropertyListing' ? 'var(--bs-accent) !important' : 'transparent',
                                    color: location.pathname === '/admin/adminPropertyListing' ? 'var(--bs-primary)' : 'var(--bs-accent)',
                                    borderRadius: "55px", margin: '5px'
                                }}
                            >
                                <ApartmentIcon style={{}} />
                                {open && <ListItemText primary="Manage Property" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip title="Add Property" placement="right">
                            <ListItemButton component={Link} to='/admin/addPropertyForm'
                                selected={location.pathname === '/admin/addPropertyForm'}
                                sx={{
                                    backgroundColor: location.pathname === '/admin/addPropertyForm' ? 'var(--bs-accent) !important' : 'transparent',
                                    color: location.pathname === '/admin/addPropertyForm' ? 'var(--bs-primary)' : 'var(--bs-accent)',
                                    borderRadius: "55px", margin: '5px'
                                }}>
                                <HomeIcon style={{}} />
                                {open && <ListItemText primary="Add Property" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                        {/* <Tooltip title="Add Employee" placement="right">
                            <ListItemButton component={Link} to='/admin/addEmployForm'>
                                <GroupIcon style={{ color: 'white' }} />
                                {open && <ListItemText primary="Add Employee" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip> */}




                        <Tooltip title="Assign Leads" placement="right">
                            <ListItemButton
                                component={Link}
                                to="/admin/assignLeads"
                                selected={location.pathname.startsWith('/admin/assignLeads')}
                                sx={{
                                    backgroundColor: location.pathname.startsWith('/admin/assignLeads')
                                        ? 'var(--bs-accent) !important'
                                        : 'transparent',
                                    color: location.pathname.startsWith('/admin/assignLeads')
                                        ? 'var(--bs-primary)'
                                        : 'var(--bs-accent)',
                                    borderRadius: "55px",
                                    margin: '5px'
                                }}
                            >
                                <QueryStatsIcon style={{}} />
                                {open && <ListItemText primary="Assign Lead" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>


                        <Tooltip title="Manage Team" placement="right">
                            <ListItemButton component={Link} to='/admin/allEmployees'
                                selected={location.pathname === '/admin/allEmployees'}
                                sx={{
                                    backgroundColor: location.pathname === '/admin/allEmployees' ? 'var(--bs-accent) !important' : 'transparent',
                                    color: location.pathname === '/admin/allEmployees' ? 'var(--bs-primary)' : 'var(--bs-accent)',
                                    borderRadius: "55px", margin: '5px'
                                }}>
                                <GroupsIcon style={{}} />
                                {open && <ListItemText primary="Manage Team" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>



                        <Tooltip title="Join Us Queries" placement="right">
                            <ListItemButton component={Link} to='/admin/allJoinUsQueries'
                                selected={location.pathname === '/admin/allJoinUsQueries'}
                                sx={{
                                    backgroundColor: location.pathname === '/admin/allJoinUsQueries' ? 'var(--bs-accent) !important' : 'transparent',
                                    color: location.pathname === '/admin/allJoinUsQueries' ? 'var(--bs-primary)' : 'var(--bs-accent)',
                                    borderRadius: "55px", margin: '5px'
                                }}>
                                <FormatListBulletedIcon style={{}} />
                                {open && <ListItemText primary="Join Us Queries" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                        <Tooltip title="Latest Leads" placement="right">
                            <ListItemButton component={Link} to='/admin/customerQueries'
                                selected={location.pathname === '/admin/customerQueries'}
                                sx={{
                                    backgroundColor: location.pathname === '/admin/customerQueries' ? 'var(--bs-accent) !important' : 'transparent',
                                    color: location.pathname === '/admin/customerQueries' ? 'var(--bs-primary)' : 'var(--bs-accent)',
                                    borderRadius: "55px", margin: '5px'
                                }}>
                                <QueryStatsIcon style={{}} />
                                {open && <ListItemText primary="Latest Leads" sx={{ marginLeft: '16px' }} />}
                            </ListItemButton>
                        </Tooltip>

                    </List>
                </div>


                <Box sx={{ padding: '10px' }}>
                    {open ? (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: 'var(--bs-accent)',
                                '&:hover': { backgroundColor: 'var(--bs-accent)' }, color: 'var(--bs-primary)'
                            }}
                            onClick={handleOpenModal}
                        >
                            Logout
                        </Button>
                    ) : (
                        <Tooltip title="Logout" placement="right">
                            <IconButton sx={{ color: 'var(--bs-accent)' }} onClick={handleOpenModal}>
                                <PowerSettingsNewIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    {/* Logout Confirmation Modal */}
                    <LogoutConfirmationModal
                        open={isModalOpen}
                        handleClose={handleCloseModal}
                        handleConfirmLogout={handleConfirmLogout}
                    />
                </Box>

            </div>
        </Drawer>
    );
};

export default Sidebar;

import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import './AddEmployForm.css';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import ApiService from '../../../config/api';
import { useNavigate, useParams } from 'react-router-dom';
import AdminNavbar from '../components/AdminNavbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from "@mui/material";

const AddEmployeeForm = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const navigate = useNavigate();
    const { id } = useParams();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");
    const [roleName, setRoleName] = useState("employee");

    const handleFirstNameChange = (e) => setFirstName(e.target.value);
    const handleLastNameChange = (e) => setLastName(e.target.value);
    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);
    const handleConfirmPwdChange = (e) => setConfirmPwd(e.target.value);

    useEffect(() => {
        if (id) {
            fetchEmployeeById(id);
        }
    }, [id]);

    const fetchEmployeeById = async (employeeId) => {
        try {
            const response = await ApiService.get(`/admin/get-employee/${employeeId}`);
            const employee = response.data.meta.employee;
            console.log(employee, "amaanId")

            setFirstName(employee.firstName);
            setLastName(employee.lastName);
            setEmail(employee.email);
            setPhoneNumber(employee.phoneNumber);
            setRoleName(employee.roleName || 'employee');
        } catch (error) {
            console.error('Error fetching employee:', error);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPwd) {
            alert('Passwords do not match');
            return;
        }

        const formData = {
            firstName,
            lastName,
            email,
            phoneNumber,
            password,
            confirmPwd,
            roleName,
        };

        setLoading(true);
        try {
            if (id) {
                const response = await ApiService.put(`/admin/update-employee/${id}`, formData);
                if (response.status === 200) {
                    navigate('/admin/allEmployees');
                    toast.success('Employee updated Successfully!');

                }
            } else {
                const response = await ApiService.post('admin/create-employee', formData);
                if (response.status === 200) {
                    setFirstName('');
                    setLastName('');
                    setEmail('');
                    setPhoneNumber('');
                    setPassword('');
                    setConfirmPwd('');
                    setRoleName('employee');
                    toast.success('Employee added Successfully!');
                    setTimeout(() => {
                        navigate('/admin/allEmployees');
                    }, 1500);

                }
            }
        } catch (error) {
            toast.error('Error in Updating agent');

        } finally {
            setLoading(false);
        }
    };

    return (
        <Box
            display="grid"
            gridTemplateColumns={sidebarOpen ? '200px 1fr' : '60px 1fr'}
            sx={{
                transition: 'grid-template-columns 0.3s ease-in-out',
                overflow: 'hidden', backgroundColor: 'var(--bs-secondary)'
            }}
        >
            <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />
            <div className=' add-agent-body'>
                <AdminNavbar />
                <div className='container-fluid mt-3'>


                    <div className="d-flex justify-content-center align-items-center  ">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-11  mt-4 ">
                                <div className="rounded-2 card add-agent-card">
                                    <div className="px-2 py-2 bg-none" style={{ borderBottom: "1px solid var(--bs-accent)" }} >
                                        <h5 className='mb-0 p-2'>{id ? 'Edit Agent' : 'Add Agent'}
                                        </h5>
                                    </div>

                                    <div className="card-box">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row text-size">
                                                <div className="mb-3 col-lg-6 col-md-6">
                                                    <label className="form-label">First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-3"
                                                        placeholder='First Name'
                                                        name="firstName"
                                                        value={firstName}
                                                        onChange={handleFirstNameChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-lg-6 col-md-6">
                                                    <label className="form-label">Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-3"
                                                        placeholder='Last Name'
                                                        name="lastName"
                                                        value={lastName}
                                                        onChange={handleLastNameChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-lg-6 col-md-6">
                                                    <label className="form-label">Email</label>
                                                    <input
                                                        type="email"
                                                        className="form-control rounded-3"
                                                        placeholder='Email'
                                                        name="email"
                                                        value={email}
                                                        onChange={handleEmailChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-lg-6 col-md-6">
                                                    <label className="form-label">Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control rounded-3"
                                                        placeholder='Phone Number'
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        onChange={handlePhoneNumberChange}
                                                    />
                                                </div>


                                                <div className="mb-3 col-lg-6 col-md-6">
                                                    <label className="form-label">Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control rounded-3"
                                                        placeholder='Password'
                                                        name="password"
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                    />
                                                </div>
                                                <div className="mb-3 col-lg-6 col-md-6">
                                                    <label className="form-label">Confirm Password</label>
                                                    <input
                                                        type="password"
                                                        className="form-control rounded-3"
                                                        placeholder='Confirm Password'
                                                        name="confirmPwd"
                                                        value={confirmPwd}
                                                        onChange={handleConfirmPwdChange}
                                                    />
                                                </div>
                                                <div className='mt-3'>
                                                    <button type="submit" className="btn btn-accent" disabled={loading}
                                                        style={{
                                                            width: '100px',
                                                        }}>
                                                        {loading ? (
                                                            <CircularProgress size={20} color="inherit" />
                                                        ) : (
                                                            id ? 'Update Agent' : 'Add Agent'
                                                        )}
                                                    </button>

                                                    <button className="btn btn-secondary mx-3 my-2" onClick={() => navigate(`/admin/allEmployees`)}>
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer />

        </Box>
    );
};

export default AddEmployeeForm;


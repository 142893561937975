import React, { useState, useEffect } from 'react';
import ApiService from '../../../config/api';
import { useNavigate } from 'react-router-dom';
import { Modal, Snackbar, Box, useTheme, useMediaQuery } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Sidebar from '../components/EmployeeSidebar';
import EmployeeNavbar from "../components/EmployeeNavbar";
import HomeIcon from '@mui/icons-material/Home';

const EmployeePropertyList = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const [properties, setProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const navigate = useNavigate();

  const getDataFromServer = async () => {
    try {
      const successResponse = await ApiService.getAll('/admin/agentProperty');
      console.log(successResponse.data.meta)
      let { meta } = successResponse.data;

      if (searchTerm) {
        meta = properties.filter(property =>
          property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          property.price.toString().includes(searchTerm) ||
          property.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
          property.agentName?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setProperties(meta);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDataFromServer();
  }, [searchTerm]);

  const editProperty = (propertyId) => {
    navigate(`/employee/employeePropertyForm`);
  };

  const handleDeleteConfirmation = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setConfirmModalOpen(true);
  };

  const confirmDeleteProperty = async () => {
    try {
      await ApiService.delete(`/admin/delete-property/${selectedPropertyId}`);
      console.log('Property deleted successfully');
      getDataFromServer();
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting property:', error);
    } finally {
      setConfirmModalOpen(false);
    }
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? '200px 1fr' : '60px 1fr'}
      sx={{
        transition: 'grid-template-columns 0.3s ease-in-out',
        overflow: 'hidden',
      }}
    >
      <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />
      <div>
        <EmployeeNavbar />
        <div className='container'>

          <div className=" col-lg-12 ">
            <div className="col-lg-12 page-head  rounded-2 d-flex justify-content-between py-3">

              {/* change by khalid  */}

              <div className="search-input-container px-2">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by title, price, city, or agent name"
                  className="form-control"
                />
                {searchTerm && (
                  <span className="clear-icon" onClick={() => setSearchTerm('')}>
                    &times;
                  </span>
                )}
              </div>

              <div>
                <button className="btn btn-primary" onClick={() => navigate('/employee/employeePropertyForm')}
                >Add Property</button>
              </div>
            </div>
          </div>
          
          {/* add logic no property found by Khalid 31/Dec/2024 */}

          <div className="row">
            {properties.length > 0 ? (
              properties.map((property) => (
                <div className="col-md-6 col-lg-4 col-sm-12" key={property._id}>
                  <div className="property-card">
                    <div className="property-image">
                      <img
                        src={
                          property.images[0] ||
                          "/assets/images/description/Contemporary_Style_Home_in_Burlingame_California_on_world_of_architecture_24.jpg"
                        }
                        className="img-fluid"
                        alt="Property"
                      />
                    </div>
                    <div className="property-details">
                      <div className="d-flex justify-content-between my-2">
                        <h5 className="mb-0">{property.title || "Heights"}</h5>
                        <p className="property-price mb-0">
                          <CurrencyRupeeIcon fontSize="16" />
                          {property.price?.toLocaleString() || "3,00,000"}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className="text-muted m-0 mb-2">{property.city || "Indore, India"}</p>
                        <p className="text-muted m-0 mb-2">{property.agentName || "Unavailable"}</p>
                      </div>
                      <div className="dot-container mb-2 text-card-new mt-2">
                        <span className="d-flex align-items-center">
                          <BedIcon style={{ fontSize: 18, marginRight: 4 }} />
                          {property.beds || "15 Beds"}
                        </span>
                        <div className="dot"></div>
                        <span className="d-flex align-items-center">
                          <BathtubIcon style={{ fontSize: 18, marginRight: 4 }} />
                          {property.baths || "15 Full Baths"}
                        </span>
                        <div className="dot"></div>
                        <span className="d-flex align-items-center">
                          <SquareFootIcon style={{ fontSize: 18, marginRight: 4 }} />
                          {property.area || "2000 Sq.Ft."}
                        </span>
                      </div>
                    </div>
                    <div className="admin-property-buttons d-flex justify-content-between" style={{ gap: "10px" }}>
                      <button className="btn" onClick={() => editProperty(property._id)}>
                        <EditIcon style={{ marginRight: "7px" }} />
                        Edit
                      </button>
                      <button className="btn" onClick={() => handleDeleteConfirmation(property._id)}>
                        <DeleteIcon style={{ marginRight: "5px" }} />
                        Delete
                      </button>
                    </div>

                  </div>
                </div>
              ))
            ) : (
              <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh' }}>
                <HomeIcon style={{ fontSize: '48px', color: '#ccc', marginBottom: '10px' }} />
                <h4>No Properties Found</h4>
              </div>
            )}
          </div>

          <Modal
            open={confirmModalOpen}
            onClose={() => setConfirmModalOpen(false)}
          >
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 300,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              textAlign: 'center'
            }}>
              <h4>Are you sure you want to delete this property?</h4>
              <Box mt={2} display="flex" justifyContent="space-around">
                <button
                  onClick={confirmDeleteProperty}
                  style={{
                    backgroundColor: 'red',
                    color: 'white',
                    padding: '8px 16px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                >
                  Confirm
                </button>
                <button
                  onClick={() => setConfirmModalOpen(false)}
                  style={{
                    backgroundColor: 'gray',
                    color: 'white',
                    padding: '8px 16px',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer'
                  }}
                >
                  Cancel
                </button>
              </Box>
            </Box>
          </Modal>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            message="Property deleted successfully!"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          />
        </div>
      </div>

    </Box>
  );
};

export default EmployeePropertyList;

import axios from 'axios';


const API_BASE_URL = 'https://eleganzaestatesolutions.com/api/';
// const API_BASE_URL = 'http://192.168.1.22:8715/api/';


const apiService = axios.create({
    baseURL: API_BASE_URL,
});

apiService.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken'); 
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);



apiService.interceptors.response.use(
    (response) => response,
    (error) => {
        // Handle global errors
        console.error('API call error:', error.response || error.message);
        return Promise.reject(error);
    }
);

const ApiService = {
    get: (url, config) => apiService.get(url, config),      
    getFilter: (url, params) => apiService.get(url, { params }),  

    post: (url, data) => apiService.post(url, data),
    put: (url, data) => apiService.put(url, data),
    delete: (url) => apiService.delete(url),
    getAll: (url) => apiService.get(url),
    search: (url) => apiService.search(url)
};



// ALL
export const LOGIN = API_BASE_URL + "user/login";
export const REGISTER = API_BASE_URL + "user/register";
export const VERIFICATION_STATUS_URL = API_BASE_URL + "user/register";
export const OTP = API_BASE_URL + "user/verify-otp";
export const RESEND_OTP = API_BASE_URL + "user/resend-otp";
export const FORGOT_PASS = API_BASE_URL + "user/forgot-password";
export const RESET_PASS = API_BASE_URL + "user/reset-password";
export const All_PROPERTIES = API_BASE_URL + "admin/properties";

export const POPULAR_PROPERTY = API_BASE_URL + "admin/properties/";
export const GET_POPULAR_PROPERTY = API_BASE_URL + "admin/properties/popular";

export const ALL_PROPERTY = API_BASE_URL + "user/public-listing";
export const ADD_COMMENT = API_BASE_URL + "admin/assign-query/comment";
export const CHANGE_STATUS = API_BASE_URL + "admin/assign-query";

export const CREATE_QUERY = API_BASE_URL + "admin/agent/query";

export const GET_LEAD= API_BASE_URL + "admin/getInfo";
export const GET_ASSIGNED_LEAD= API_BASE_URL + "admin/getAll-assignedInfo";    
export const ADD_INFO_COMMENT = API_BASE_URL + "admin/assigned-info/comment";
export const CHANGE_STATUS_INFO = API_BASE_URL + "admin/assign-info";

export default ApiService;

import React, { useState, useEffect } from 'react';
import ApiService from '../../config/api';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Sidebar from '../employee/components/EmployeeSidebar';
import EmployeeNavbar from "../employee/components/EmployeeNavbar";
import { ALL_PROPERTY } from '../../config/api';

const EmployeeAllProperty = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const [properties, setProperties] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const getDataFromServer = async () => {
        try {
            const successResponse = await ApiService.getAll(ALL_PROPERTY);
            console.log(successResponse.data.meta.properties);
            let properties = successResponse.data.meta.properties;

            if (searchTerm) {
                properties = properties.filter(property =>
                    property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    property.price.toString().includes(searchTerm) ||
                    property.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    property.agentName?.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }

            setProperties(properties);
        } catch (error) {
            console.log('Error fetching properties:', error);
        }
    };

    useEffect(() => {
        getDataFromServer();
    }, [searchTerm]);

    return (
        <Box
            display="grid"
            gridTemplateColumns={sidebarOpen ? '200px 1fr' : '60px 1fr'}
            sx={{
                transition: 'grid-template-columns 0.3s ease-in-out',
                overflow: 'hidden',
            }}
        >
            <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />
            <div>
                <EmployeeNavbar />
                <div className='container'>
                    <div className="col-lg-12">
                        <div className="col-lg-12 page-head rounded-2 d-flex justify-content-between py-3">

                            {/* change here by Khalid */}

                            <div className="search-input-container px-2">
                                <input
                                    type="text"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    placeholder="Search by title, price, city, or agent name"
                                    className="form-control"
                                />
                                {searchTerm && (
                                    <span className="clear-icon" onClick={() => setSearchTerm('')}>
                                        &times;
                                    </span>
                                )}
                            </div>
                            <div>
                                <button className="btn btn-primary" onClick={() => navigate('/employee/employeePropertyForm')}>
                                    Add Property
                                </button>
                            </div>
                        </div>
                    </div>

                    {/* add logic no property found by Khalid 31/Dec/2024 */}

                    {properties.length === 0 ? (
                        <div className="d-flex flex-column align-items-center justify-content-center" style={{ height: '50vh' }}>
                            <HomeIcon style={{ fontSize: '48px', color: '#ccc', marginBottom: '10px' }} />
                            <h4>No Properties Found</h4>
                        </div>
                    ) : (
                        <div className='row'>
                            {properties.map((property) => (
                                <div className="col-md-6 col-lg-4 col-sm-12" key={property._id}>
                                    <div className="property-card">
                                        <div className="property-image">
                                            <img
                                                src={property.images[0] || "/assets/images/description/Contemporary_Style_Home_in_Burlingame_California_on_world_of_architecture_24.jpg"}
                                                className="img-fluid"
                                                alt="Property"
                                            />
                                        </div>
                                        <div className="property-details">
                                            <div className='d-flex justify-content-between my-2'>
                                                <h5 className="mb-0">{property.title || "Heights"}</h5>
                                                <p className="property-price mb-0">
                                                    <CurrencyRupeeIcon fontSize="16" />
                                                    {property.price?.toLocaleString() || "3,00,000"}
                                                </p>
                                            </div>
                                            <div className='d-flex justify-content-between'>
                                                <p className="text-muted m-0 mb-2">{property.city || "Indore, India"}</p>
                                                <p className="text-muted m-0 mb-2">{property.agentName || "Unavailable"}</p>
                                            </div>
                                            <div className="dot-container mb-2 text-card-new mt-2">
                                                <span className='d-flex align-items-center'>
                                                    <BedIcon style={{ fontSize: 18, marginRight: 4 }} />
                                                    {property.beds || "15 Beds"}
                                                </span>
                                                <div className="dot"></div>
                                                <span className='d-flex align-items-center'>
                                                    <BathtubIcon style={{ fontSize: 18, marginRight: 4 }} />
                                                    {property.baths || "15 Full Baths"}
                                                </span>
                                                <div className="dot"></div>
                                                <span className='d-flex align-items-center'>
                                                    <SquareFootIcon style={{ fontSize: 18, marginRight: 4 }} />
                                                    {property.area || "2000 Sq.Ft."}
                                                </span>
                                            </div>
                                        </div>
                                        {/* Add the Create Lead button here */}
                                        <div className="property-actions">
                                            <button className="btn btn-primary w-100"
                                                onClick={() =>
                                                    navigate(`/employee/EmployeeCreateLead/${property._id}`, {
                                                        state: { title: property.title },
                                                    })
                                                }
                                            >Create Lead</button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                    )}
                </div>
            </div>
        </Box>
    );
};

export default EmployeeAllProperty;

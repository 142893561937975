import React, { useEffect, useState } from "react";
import Sidebar from '../components/Sidebar';
import { Box, useMediaQuery, useTheme, Modal } from '@mui/material';
import ApiService from '../../../config/api';
import { CheckCircle } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import '../adminPropertyListing/AdminPropertyListing.css';
import { useNavigate } from 'react-router-dom';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AdminNavbar from "../components/AdminNavbar";
import Snackbar from '@mui/material/Snackbar';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { POPULAR_PROPERTY } from "../../../config/api";
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';

const AdminPropertyListing = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };
    const [properties, setProperties] = useState([]);
    const [filter, setFilter] = useState('pending');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const navigate = useNavigate();

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedPropertyId, setSelectedPropertyId] = useState(null);

    const [searchTerm, setSearchTerm] = useState('');
    const getDataFromServer = async () => {
        try {
            const successResponse = await ApiService.getAll('/admin/properties');
            let { meta: { properties } } = successResponse.data;

            if (searchTerm) {
                properties = properties.filter(property =>
                    property.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    property.price.toString().includes(searchTerm) ||
                    property.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    property.agentName?.toLowerCase().includes(searchTerm.toLowerCase())
                );
            }

            setProperties(properties);
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        getDataFromServer();
    }, [searchTerm]);



    const pendingPropertiesCount = properties.filter(property => property.adminApproval === 'pending').length;

    const handleOpenModal = (message) => {
        setModalMessage(message);
        setModalOpen(true);
        setTimeout(() => {
            setModalOpen(false);
        }, 2000);
    };

    const updateApprovalStatus = async (propertyId, status) => {
        try {
            await ApiService.put('/admin/updateAdminApproval', {
                propertyId,
                adminApproval: status
            });
            setProperties(prevState =>
                prevState.map(property =>
                    property._id === propertyId ? { ...property, adminApproval: status } : property
                )
            );
            handleOpenModal(`Listing ${status} successfully!`);
        } catch (error) {
            console.log(error);
        }
    };

    const editProperty = (propertyId) => {
        navigate(`/admin/editPropertyForm/${propertyId}`);

    }

    const handleDeleteConfirmation = (propertyId) => {
        setSelectedPropertyId(propertyId);
        setConfirmModalOpen(true);
    };

    const confirmDeleteProperty = async () => {
        try {
            await ApiService.delete(`/admin/delete-property/${selectedPropertyId}`);
            console.log('Property deleted successfully');
            getDataFromServer();
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error deleting property:', error);
        } finally {
            setConfirmModalOpen(false);
        }
    };
    const renderPropertyCard = (property) => (
        <div className="col-md-6 col-lg-4 col-sm-12">
            <div className="property-card">
                <div
                    className="property-image position-relative"
                    style={{ position: "relative" }}
                >
                    <img
                        src={property.images[0]}
                        className="img-fluid"
                        alt="Property"
                    />
                      {property.adminApproval !== "pending" && property.adminApproval !== "rejected" && (
                    <span
                        style={{
                            position: "absolute",
                            top: "20px",
                            right: "20px",
                            backgroundColor: "#F4EBD0",
                            padding: "5px",
                            borderRadius: "50%",
                            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                            cursor: "pointer",
                        }}
                        onClick={() => togglePopular(property._id)}
                    >
                        <StarIcon
                            style={{
                                color: property.isPopular ? "#D8B46A" : "gray",
                                fontSize: "24px",
                                transition: "color 0.3s ease",
                            }}
                        />
                    </span>
                      )}
                </div>
                <div className="property-details">
                    <div className="d-flex justify-content-between my-2">
                        <h5 className="mb-0">{property.title || "Heights"}</h5>
                        <p className="property-price mb-0">
                            <CurrencyRupeeIcon fontSize="16" />
                            {property.price.toLocaleString() || "3,00,000"}
                        </p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="text-muted m-0 mb-2">{property.city || "Indore, India"}</p>
                        <p className="text-muted m-0 mb-2">{property.agentName || "Unavailable"}</p>
                    </div>
                    <div className="dot-container mb-2 text-card-new mt-2">
                        <span className="d-flex align-items-center">
                            <BedIcon style={{ fontSize: 18, marginRight: 4 }} />
                            {property.numberOfBedrooms || ""}
                        </span>
                        <div className="dot"></div>
                        <span className="d-flex align-items-center">
                            <BathtubIcon style={{ fontSize: 18, marginRight: 4 }} />
                            {property.numberOfBathrooms || ""}
                        </span>
                        <div className="dot"></div>
                        <span className="d-flex align-items-center">
                            <SquareFootIcon style={{ fontSize: 18, marginRight: 4 }} />
                            {property.area || "2000 Sq.Ft."}
                        </span>
                    </div>
                </div>
                <div className="admin-property-buttons">
                    {property.adminApproval === "approved" && (
                        <>
                            <button
                                className="btn me-1"
                                onClick={() => editProperty(property._id, "edit")}
                                style={{ cursor: "pointer" }}
                            >
                                <EditIcon style={{ marginRight: "7px" }} />
                                Edit
                            </button>
                            <button
                                className="btn"
                                onClick={() => handleDeleteConfirmation(property._id, "deleted")}
                                style={{ cursor: "pointer" }}
                            >
                                <DeleteIcon style={{ marginRight: "5px" }} />
                                Delete
                            </button>
                        </>
                    )}
                    {property.adminApproval === "pending" && (
                        <>
                            <button
                                className="btn me-1"
                                onClick={() => updateApprovalStatus(property._id, "approved")}
                            >
                                <CheckCircleIcon
                                    style={{ marginRight: "5px" }}
                                />
                                Approve
                            </button>
                            <button
                                className="btn"
                                onClick={() => updateApprovalStatus(property._id, "rejected")}
                            >
                                <CancelIcon
                                    style={{ marginRight: "5px" }}
                                />
                                Reject
                            </button>
                        </>
                    )}
                    {property.adminApproval === "rejected" && (
                        <button
                            className="btn btn-secondary"
                            onClick={() => updateApprovalStatus(property._id, "pending")}
                        >
                            Move to Pending
                        </button>
                    )}
                </div>
            </div>

        </div>

    );

    const renderPropertyList = () => {
        const filteredProperties = properties.filter(property => property.adminApproval === filter);

        if (filteredProperties.length === 0) {
            return <div className="no-properties-text">No properties in {filter}</div>;
        }

        return filteredProperties.map(property => renderPropertyCard(property));
    };


    const togglePopular = async (id) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) {
                alert('No access token found. Please log in.');
                return;
            }
            const url = `${POPULAR_PROPERTY}${id}/toggle-popular`;

            const response = await fetch(url, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Failed to toggle property popularity');
            }

            const data = await response.json();
            getDataFromServer();

            alert('Successful.!');
        } catch (err) {
            alert('Error occurred while toggling property popularity');
        }
    };


    return (
        <Box
            display="grid"
            gridTemplateColumns={sidebarOpen ? '200px 1fr' : '60px 1fr'}
            sx={{
                transition: 'grid-template-columns 0.3s ease-in-out',
                overflow: 'hidden',
                minHeight: '100vh', backgroundColor: 'var(--bs-secondary)', overflowY: 'auto',
            }}
        >
            <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />
            <div className=' '>
                <AdminNavbar />

                <Box p={3} sx={{
                    height: 'auto', p: {
                        xs: 1.5,
                    },
                }}>


                    <div className=" ">
                        <div className="page-head rounded-2 row justify-content-between">
                            <div className="col-lg-4 col-md-12 col-sm-12 d-flex flex-column flex-md-row  ">
                                <button
                                    className={`margin-btn mb-1 ${filter === 'approved' ? 'custom-active' : 'custom-inactive'}`}
                                    onClick={() => setFilter('approved')}
                                >
                                    Approved
                                </button>
                                <button
                                    className={`margin-btn mb-1 pending-button ${filter === 'pending' ? 'custom-active' : 'custom-inactive'}`}
                                    onClick={() => setFilter('pending')}
                                >
                                    Pending
                                    {pendingPropertiesCount > 0 && (
                                        <span className="notification-badge">{pendingPropertiesCount}</span>
                                    )}
                                </button>
                                <button
                                    className={`mb-1 margin-btn ${filter === 'rejected' ? 'custom-active' : 'custom-inactive'}`}
                                    onClick={() => setFilter('rejected')}
                                >
                                    Rejected
                                </button>
                            </div>

                            <div className="col-lg-7 lg-ms-4 sm-ms-4 col-md-12 col-sm-12 d-flex  flex-md-row align-items-center">
                                <div className="search-input-container flex-grow-1 mb-2 mb-md-0">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        placeholder="Search by title, price, city, or agent name "
                                        className="form-control mb-1"
                                    />
                                    {searchTerm && (
                                        <span className="clear-icon" onClick={() => setSearchTerm('')}>
                                            &times;
                                        </span>
                                    )}
                                </div>
                                <div className="ms-md-2">
                                    <button className="btn btn-primary mb-1" onClick={() => navigate('/admin/addPropertyForm')}>
                                        Add Property
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <div className=" row">
                            {renderPropertyList()}
                        </div>
                    </div>
                </Box>
            </div>

            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 300,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        textAlign: 'center'
                    }}
                >
                    <h4>
                        <CheckCircle color="success" />
                        {modalMessage}
                    </h4>
                </Box>
            </Modal>

            <Modal
                open={confirmModalOpen}
                onClose={() => setConfirmModalOpen(false)}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center'
                }}>
                    <h4>Are you sure you want to delete this property?</h4>
                    <Box mt={2} display="flex" justifyContent="space-around">
                        <button
                            onClick={confirmDeleteProperty}
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Confirm
                        </button>
                        <button
                            onClick={() => setConfirmModalOpen(false)}
                            style={{
                                backgroundColor: 'gray',
                                color: 'white',
                                padding: '8px 16px',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                        >
                            Cancel
                        </button>
                    </Box>
                </Box>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                message="Property deleted successfully!"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />


        </Box>
    );
};

export default AdminPropertyListing;

import React, { useState } from 'react';
import Sidebar from '../../pages/employee/components/EmployeeSidebar';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import EmployeeNavbar from '../../pages/employee/components/EmployeeNavbar';
import { Form, Button, Spinner } from "react-bootstrap";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { CREATE_QUERY } from '../../config/api';

const EmployeeCreateLead = () => {
    const location = useLocation();
    const { title } = location.state || {};
    const { propertyId } = useParams();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
    const toggleDrawer = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [priority, setPriority] = useState("choose priority");
    const [loading, setLoading] = useState(false);  // Loader state
    const navigate = useNavigate();  // For navigation

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);  // Start loader

        const token = localStorage.getItem('accessToken');
        const body = {
            propertyId,
            name,
            email,
            phone: number,
            priority,
        };

        try {
            const response = await axios.post(CREATE_QUERY, body, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            alert("Query submitted successfully!");
            navigate('/employee/assignedLeads');  // Redirect to assigned leads page
        } catch (error) {
            alert("Failed to submit query!");
        } finally {
            setLoading(false);  // Stop loader
        }
    };

    return (
        <Box
            display="grid"
            gridTemplateColumns={sidebarOpen ? '200px 1fr' : '60px 1fr'}
            sx={{
                transition: 'grid-template-columns 0.3s ease-in-out',
                overflow: 'hidden',
            }}
        >
            <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />
            <div>
                <EmployeeNavbar />
                <div className="container py-5">
                    <div className="card rounded shadow-sm">
                        <div className="card-header">
                            <h5 className="mb-0">{title}</h5>
                        </div>
                        <div className="card-body">
                            <Form onSubmit={handleSubmit}>
                                <Form.Group controlId="formName" className="mb-3">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formEmail" className="mb-3">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNumber" className="mb-3">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter phone number"
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group controlId="formPriority" className="mb-3">
                                    <Form.Label>Priority</Form.Label>
                                    <Form.Control
                                        as="select"
                                        value={priority}
                                        onChange={(e) => setPriority(e.target.value)}
                                    >
                                        <option value="choose priority">Choose priority</option>
                                        <option value="Hot">Hot</option>
                                        <option value="Warm">Warm</option>
                                        <option value="Cold">Cold</option>
                                    </Form.Control>
                                </Form.Group>

                                <Button variant="primary" type="submit" className="w-100">
                                    {loading ? (
                                        <Spinner animation="border" size="sm" /> // Show loader
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Box>
    );
};

export default EmployeeCreateLead;

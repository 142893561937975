
import '../homePage/HomePage.css'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Link } from 'react-router-dom';
import React, { Component, useEffect, useRef, useState } from "react";
import StarIcon from '@mui/icons-material/Star';
import PoolIcon from '@mui/icons-material/Pool';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import GamesIcon from '@mui/icons-material/Games';
import ParkIcon from '@mui/icons-material/Park';
import DiamondIcon from '@mui/icons-material/Diamond';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import Faq from '../components/faq/Faq';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchIcon from '@mui/icons-material/Search';
import Navbar from '../components/navbar/Navbar'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import RoomIcon from '@mui/icons-material/Room';

import Footer from "../components/footer/Footer";
import ApiService from '../../../config/api';
import { useNavigate } from "react-router-dom";

import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';

import PropertyInquiryModal from "../BuyPage/PropertyInquiryModal ";
import TimeAgo from '../components/timeAgo/TimeAgo';
import { GET_POPULAR_PROPERTY } from "../../../config/api";
import logo from "../../../assets/rating.png"


function VerticalMode({ image1, image2, image3, image4, image5, delay }) {

  const sliderRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.slickPlay();
      }
    }, delay);

    // return () => clearTimeout(timer);
  }, [delay]);

  const settings = {
    arrows: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: true,

    dots: false,
    infinite: true,

    vertical: true,
    verticalSwiping: true,
    beforeChange: function (currentSlide, nextSlide) {
    },
    afterChange: function (currentSlide) {
    }
  };
  return (
    <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
          <span className="wrapper"><img src={image1} className="mb-4 " alt="" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image2} className="mb-4 " alt="" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image3} className="mb-4 " alt="" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image4} alt="" className="mb-4" /></span>
        </div>
        <div>
          <span className="wrapper"><img src={image5} alt="" className="mb-4 " /></span>
        </div>

      </Slider>
    </div>
  );
}


const testimonials = [
  {
    name: "MOHAMMAD KHALID",
    text: "This website made finding the perfect property stress-free and efficient. The detailed listings and high-quality images were exactly what I needed to make an informed decision. Great service!",
    image: "https://static.vecteezy.com/system/resources/previews/022/127/318/non_2x/avatar-photo-placeholder-icon-of-a-man-in-a-shirt-and-tie-vector.jpg",
  },
  {
    name: "ARSALAN KHAN",
    text: "I was impressed with the personalized recommendations I received. The team truly understood my needs and helped me find the ideal home for my family. Excellent support throughout the process!",
  },
  {
    name: "KHIZAR SAEED KHAN",
    text: "As a landlord, listing my property here was quick and simple. I found trustworthy tenants within days, and the support team was always available to help with any questions.",
  },
  {
    name: "MURTAZA MALIK",
    text: "The platform is user-friendly and loaded with great features. I especially loved the virtual tours—it felt like I was visiting the property in person! Highly recommend it to anyone looking for real estate solutions.",
  },
  {
    name: "ARBAZ KHAN",
    text: "Being a first-time buyer, I was nervous about the process, but this website made everything so easy. The step-by-step guidance and market insights were invaluable. Thank you for helping me find my first home!",
  },
  {
    name: "ZAID ULLAH KHAN",
    text: "The real-time updates on new properties and pricing changes helped me stay ahead of the market. This platform is a game-changer for serious property buyers.",
  }
];




const HomePage = () => {

  const [searchQuery, setSearchQuery] = useState("");
  const [properties, setProperties] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);

  const navigate = useNavigate(); // React Router's useNavigate hook

  // Function to handle search query submission
  const handleSearch = async () => {
    try {
      const response = await ApiService.get(`/admin/search`, {
        params: {
          searchValue: searchQuery, // Match the parameter name expected by the backend
        },
      });
      console.log("Search Results:", response.data.data);
      navigate("/buyPage", { state: { searchResults: response.data.data } });

    } catch (error) {
      console.error("Error searching:", error);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const getDataFromServer = async () => {
    try {
      // Make the API call to fetch popular properties
      const successResponse = await ApiService.getAll(GET_POPULAR_PROPERTY);

      // Extract the properties from the response
      const { popularProperties } = successResponse.data;

      // Set the properties to the state
      setProperties(popularProperties); // Assuming setProperties is your state setter for properties

    } catch (error) {
      console.error('Error fetching popular properties:', error);
      // Handle errors as needed (e.g., show an alert or set an error state)
    }
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const openInquiryModal = (propertyId) => {
    setSelectedPropertyId(propertyId);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const navigateToBuy = () => {
    navigate(`/buyPage?search=${searchQuery}`)
  }


  return (

    <div className='background-main'>
      <Navbar className="" />


      {/* ------hero------------------ */}

      <div className='hero  d-flex align-items-center text-center'>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 hero-text ">
              <h1>Real Estate Reimagined. Find your perfect place</h1>
              <p className='mb-5'>
                Discover your dream home with us. Expert guidance, personalized service, and a vast selection of properties await. Let's find your perfect place today.
              </p>
              <div className="inline search-bar-container">
                <input
                  type="text"
                  className="form-control search-form"
                  placeholder="Search Property"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery && (
                  <button className="clear-btn absolute" onClick={navigateToBuy}>
                    &times;
                  </button>
                )}
                <button className="btn search-btn btn-hoverrr" type="button" onClick={navigateToBuy}>
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  New Card  added by Khalid 31/Dec/2024 */}

      <div className="container feature-section">
        <div className="col-lg-8">
          <h2 className="text-color">Popular Properties</h2>
        </div>
        <div className="row my-4">
          {properties.slice(0, 6).map((property, index) => (
            <div key={index} className="col-md-6 col-lg-4 col-sm-12">
              <div className="property-card">
                <div className="property-image-container position-relative">
                  <div
                    className="property-image"
                    onClick={() => navigate(`/propertyDescription/${property._id}`)}
                  >
                    <img
                      src={property.images[0] || "default-image.jpg"}
                      className="img-fluid"
                      alt="Property"
                    />
                  </div>

                  {/* Add Popular Icon  by Khalid 03/Jan/2024 */}

                  <div
                    className="popular-icon position-absolute"
                    style={{
                      top: "0px",
                      right: "0px",
                      borderRadius: "50%",
                      padding: "5px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="Popular"
                      style={{ width: "70px", height: "70px" }}
                    />
                  </div>
                </div>

                <div className="property-details">
                  <div className="d-flex justify-content-between my-2">
                    <h5 className="mb-0 homePage-title-heading">
                      {property.title || "Property"}
                    </h5>
                    <p className="property-price mb-0">
                      <CurrencyRupeeIcon fontSize="16" />
                      {property.price
                        ? `${property.price.toLocaleString("en-IN")}`
                        : "Price not available"}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between my-2">
                    <p className="text-muted m-0">{property.city || "Unknown City"}</p>
                    <p className="text-muted m-0">
                      <TimeAgo createdAt={property.createdAt} />
                    </p>
                  </div>

                  <div className="dot-container mb-2 text-card-new mt-2">
                    <span className="d-flex align-items-center">
                      <BedIcon style={{ fontSize: 18, marginRight: 4 }} />
                      {property.numberOfBedrooms}
                    </span>
                    <div className="dot"></div>
                    <span className="d-flex align-items-center">
                      <BathtubIcon
                        style={{
                          fontSize: 18,
                          marginRight: 4,
                          marginBottom: 4,
                        }}
                      />
                      {property.numberOfBathrooms}
                    </span>
                    <div className="dot"></div>
                    <span className="d-flex align-items-center">
                      <SquareFootIcon style={{ fontSize: 18, marginRight: 4 }} />
                      {property.area}
                    </span>
                  </div>
                </div>

                <div className="property-buttons">
                  <button
                    className="btn"
                    onClick={() => navigate(`/propertyDescription/${property._id}`)}
                  >
                    View Details
                  </button>
                  <button
                    className="btn"
                    onClick={() => openInquiryModal(property._id)}
                  >
                    Get a Quote
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="d-flex justify-content-end">
          <button
            onClick={() => navigate(`/buyPage`)}
            className="btn-outline px-3 py-2 rounded-2 bg-transparent"
            style={{
              color: "var(--bs-accent)",
              border: "2px solid var(--bs-accent)",
            }}
          >
            See more
          </button>
        </div>
      </div>

      <div className="container reviews ">
        <div className="row mt-5">
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="explore-box text-color">
              <h3>
                Discover your dream home with our extensive real estate listings. From cozy apartments to luxurious villas, find properties that suit your lifestyle and budget. Start your journey to a new home today.
              </h3>
            </div>

          </div>
        </div>

      </div>


      {/* ----------------------------instance-valuation by Khalid-------------------------------*/}
      <div className="container  padding-box mt-4 mb-5 " >
        <div className=" row  rounded-4 box-color">

          <div className="col-lg-6 col-md-12 col-sm-12 valuation-module d-flex flex-column justify-content-center">
            <div className="">
              <h3 className='valuation-text'>Find Your Dream Home Today</h3>

            </div>
            <p className='valuation-text'>Finding a home that fits your lifestyle has never been easier. Browse through our curated selection of properties, and let us guide you to the home that aligns with your dreams and needs. Begin your search today. </p>

          </div>
          <div className="col-lg-6   carousal-wrapper">

            <span className='d-flex'>
              <VerticalMode image1="/assets/images/homePageImg/vc1.webp"
                image2="/assets/images/homePageImg/vc1.2.webp"
                image3="/assets/images/homePageImg/vc1.3.webp"
                image4="/assets/images/homePageImg/vc1.4.webp"
                image5="/assets/images/homePageImg/vc1.5.webp"
                delay={300}
              />

              <VerticalMode image1="/assets/images/homePageImg/vc2.webp"
                image2="/assets/images/homePageImg/vc2.2.webp"
                image3="/assets/images/homePageImg/vc2.3.webp"
                image4="/assets/images/homePageImg/vc2.4.webp"
                image5="/assets/images/homePageImg/vc2.5.webp"
                delay={300}
              />

              <VerticalMode image1="/assets/images/homePageImg/v3.webp"
                image2="/assets/images/homePageImg/vc3.2.webp"
                image3="/assets/images/homePageImg/vc3.3.webp"
                image4="/assets/images/homePageImg/vc3.4.webp"
                image5="/assets/images/homePageImg/vc3.5.webp"
                delay={2000}
              />

            </span>
          </div>

        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 flex items-center justify-center text-center m-auto">
            <div>
              <h2 className="font-bold text-white text-3xl mb-2 px-5">
                This is what other Owners & Dealers have to say...
              </h2>
              <p className="text-lg mb-4 text-white">
                We place huge value on strong relationships and have seen the benefit they bring to our business. Customer feedback is vital in helping us to get it right.
              </p>
            </div>
          </div>

          {/* Right Side Slider  added by Khalid 31/Dec/2024  */}
          <div className="col-12 col-md-6"
            style={{ height: "200px" }} >
            <Swiper
              spaceBetween={50}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              slidesPerView={1}
              // navigation={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
            >
              {testimonials.map((testimonial, index) => (
                <SwiperSlide key={index}>
                  <div className="text-white p-6 text-center flex items-center justify-center"
                    style={{ height: "200px" }} >
                    <div>
                      <h3 className="font-bold text-2xl mb-2">{testimonial.name}</h3>
                      <div className="w-8 h-[2px] bg-white mx-auto mb-8"></div>
                      <p className="px-40 text-lg">{testimonial.text}</p>
                    </div>
                  </div>

                </SwiperSlide>
              ))}
            </Swiper>
          </div>

        </div>
      </div>


      {/* -----------------accordian by Khalid 07/Jan/2025 ---------------------- */}

      <div className='accordion mt-4'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="mb-3 mt-3 "><h2>Why choose Eleganza</h2></div>

              <Faq></Faq>
            </div>
          </div>
        </div>
      </div>

      <PropertyInquiryModal open={modalOpen} handleClose={closeModal} propertyId={selectedPropertyId} />

      <Footer />

    </div >


  )
}

export default HomePage

import React, { useEffect, useState } from "react";
import Sidebar from "../components/EmployeeSidebar";
import EmployeeNavbar from "../components/EmployeeNavbar";
import { Box, useMediaQuery, useTheme, Snackbar, Alert, Card, CardContent, Typography, Button, Select, MenuItem, FormControl, InputLabel, Grid, TextField } from "@mui/material";
import ApiService from "../../../config/api";
import PropertyDetailsModal from "./PropertyDetailsModal";
import "./AssignedLeads.css";
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { ADD_INFO_COMMENT, CHANGE_STATUS_INFO } from "../../../config/api";
import { useNavigate } from "react-router-dom";


const EmployeeAssignPhoneLead = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [sidebarOpen, setSidebarOpen] = useState(!isSmallScreen);
  const [assignedQueries, setAssignedQueries] = useState([]);
  const [filteredQueries, setFilteredQueries] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [comments, setComments] = useState({});
  const [statuses, setStatuses] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const fetchAssignedQueries = async () => {
      try {
        const accessToken = await localStorage.getItem('accessToken');
        if (!accessToken) {
          console.error("No access token found.");
          return;
        }
  
        const response = await ApiService.get("admin/assignedInfo", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        console.log(response);
  
        if (response?.data?.success && response?.data?.data?.length > 0) {
          // Filter out queries with status 'Failed' or 'Closed'
          const filteredData = response.data.data.filter(query =>
            query.status !== "Failed" && query.status !== "Closed"
          );
  
          setAssignedQueries(filteredData);
          setFilteredQueries(filteredData);
  
          const initialStatuses = {};
          const initialComments = {};
  
          filteredData.forEach(query => {
            initialStatuses[query._id] = query.status || "Pending";
            initialComments[query._id] = query.comment || "";
          });
  
          setStatuses(initialStatuses);
          // setComments(initialComments);
        } else {
          console.log("No assigned queries found.");
        }
      } catch (error) {
        console.error("Error fetching assigned queries:", error);
      }
    };
  
    fetchAssignedQueries();
  }, []);
  





  const handleStatusChange = async (queryId, event) => {
    const newStatus = event.target.value;

    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [queryId]: newStatus,
    }));

    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        setLoading(false);
        return;
      }

      const response = await axios.put(
        `${CHANGE_STATUS_INFO}/${queryId}/status`,
        { status: newStatus },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSnackbarMessage('Status updated successfully');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Error updating status');
      setSnackbarOpen(true);
    }
  };


  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);

    if (value) {
      const filtered = assignedQueries.filter(query =>
        query.comment && query.comment.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredQueries(filtered);
    } else {
      setFilteredQueries(assignedQueries);
    }
  };

  const handleFilterChange = (event) => {
    const selectedStatus = event.target.value;
    setStatusFilter(selectedStatus);

    if (selectedStatus) {
      const filtered = assignedQueries.filter(query => query.status === selectedStatus);
      setFilteredQueries(filtered);
    } else {
      setFilteredQueries(assignedQueries);
    }
  };

  const handleCommentChange = (queryId, event) => {
    const newComments = { ...comments, [queryId]: event.target.value };
    setComments(newComments);
  };


  const handleCommentSubmit = async (infoId, comment) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("Token not found in localStorage.");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        ADD_INFO_COMMENT,
        { infoId, comment },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSnackbarMessage("Comment added successfully");
      setSnackbarOpen(true);
      setComments("");
    } catch (error) {
      setSnackbarMessage("Error adding comment");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProperty(null);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={sidebarOpen ? "200px 1fr" : "60px 1fr"}
      sx={{
        transition: "grid-template-columns 0.3s ease-in-out",
        overflow: "hidden",
      }}
    >
      <Sidebar open={sidebarOpen} toggleDrawer={toggleDrawer} setOpen={setSidebarOpen} />
      <div>
        <EmployeeNavbar />
        <div className="container mt-3">
          <div className="col-lg-12">
            <Box
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
              sx={{ paddingBottom: '8px' }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  borderColor: "var(--bs-primar)",
                  width: '15%',
                  color: "var(--bs-primary)",
                  "&:hover": {
                    backgroundColor: "var(--bs-accent)",
                  },
                  alignItems: "center",
                }}
                onClick={() => navigate("/employee/assignedLeads")}
              >
                Website Leads
              </Button>
              <Button variant="contained"
                sx={{
                  backgroundColor: "var(--bs-accent)",
                  borderColor: "var(--bs-accent)",
                  width: '15%',
                  color: "var(--bs-primary)",
                  "&:hover": {
                    backgroundColor: "var(--bs-accent)",
                  },
                  alignItems: "center",
                }}
                onClick={() => navigate("/employeeAssignPhoneLead")}>
                Phone Leads

              </Button>
            </Box>
            <div className="col-lg-12 page-head rounded-2 d-flex justify-content-between align-items-center">

              {/* Status Filter  by Khalid */}

              <div className="status-filter-container">
                <FormControl size="small" fullWidth sx={{ width: '250px' }}>
                  <InputLabel>Status Filter</InputLabel>
                  <Select
                    value={statusFilter}
                    onChange={handleFilterChange}
                    label="Status Filter"
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Contacted">Contacted</MenuItem>
                    <MenuItem value="Site visited">Site visited</MenuItem>
                    <MenuItem value="Hold">Hold</MenuItem>
                    <MenuItem value="Failed">Failed</MenuItem>
                    <MenuItem value="Not interested">Not interested</MenuItem>
                    <MenuItem value="Interested">Interested</MenuItem>
                    <MenuItem value="Closed">Closed</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="search-input-container px-2">
                <input
                  type="text"
                  placeholder="Search by comment...."
                  className="form-control"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>

              {/* Create Lead Button  by Khalid */}

            </div>
          </div>

          <div className="row">
            {filteredQueries.length === 0 ? (
              <div className="col-12 mb-4">
                <Card>
                  <CardContent>
                    <Typography variant="h6" align="center">
                      No queries found.
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            ) : (
              filteredQueries.map((query) => (
                <div className="col-12 col-md-12 mb-4" key={query._id}>
                  <Card
                    sx={{
                      border: '1px solid #e0e0e0',
                      borderRadius: '8px',
                      boxShadow: 'none',
                      '&:hover': {
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                      },
                    }}
                  >
                    <CardContent>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} sm={8}>
                          <Typography variant="h6" component="div">
                          {query.agent.firstName} {query.agent.lastName}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: '1.1rem' }}
                          >
                            {query.agent.email} | +91 {query.agent.phoneNumber}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: '1rem' }}
                          >
                            Assigning Time :{' '}
                            {new Date(query.createdAt).toLocaleString()}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: '1rem' }}
                          >
                            Priority: {query.priority}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: '1.1rem' }}
                          >
                           budget : {query.infoId[0]?.budget}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ fontSize: '1.1rem' }}
                          >
                           Property Type : {query.infoId[0]?.propertyType}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="black"
                            sx={{ fontSize: '1.1rem' }}
                          >
                           Message : {query.infoId[0]?.message || 'No message available'}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ marginTop: 1, width: '100%', fontSize: '1rem' }}
                          >
                            Comment : {comments[query._id] || query.comment || 'No comment yet'}
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4} container direction="column" alignItems="flex-end">
                          {/* Status Dropdown */}
                          <FormControl size="small" fullWidth sx={{ marginBottom: 1 }}>
                            <InputLabel>Status</InputLabel>
                            <Select
                              value={statuses[query._id] || query.status || 'Pending'}
                              onChange={(e) => handleStatusChange(query._id, e)}
                              label="Status"
                              disabled={loading}
                            >
                              <MenuItem value="Pending">Pending</MenuItem>
                              <MenuItem value="Contacted">Contacted</MenuItem>
                              <MenuItem value="Site visited">Site visited</MenuItem>
                              <MenuItem value="Hold">Hold</MenuItem>
                              <MenuItem value="Failed">Failed</MenuItem>
                              <MenuItem value="Not interested">Not interested</MenuItem>
                              <MenuItem value="Interested">Interested</MenuItem>
                              <MenuItem value="Closed">Closed</MenuItem>
                            </Select>
                          </FormControl>

                          {/* Comment Input */}
                          <TextField
                            label="Add Comment"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={comments[query._id] || ''}
                            onChange={(e) => handleCommentChange(query._id, e)}
                            sx={{ marginBottom: 2 }}
                          />

                          {/* Action Buttons */}
                          <Grid
                            container
                            spacing={1}
                            alignItems="center"
                            direction="row"
                            justifyContent="flex-end"
                          >
                            <Grid item>
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                  backgroundColor: '#122620',
                                  '&:hover': {
                                    backgroundColor: '#0e1f1f',
                                  },
                                  width: '150px',
                                }}
                                onClick={() =>
                                  handleCommentSubmit(query.infoId[0]._id, comments[query._id] || '')
                                }
                                disabled={loading}
                              >
                                {loading[query._id] ? <CircularProgress size={24} /> : 'Submit'}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              ))
            )}
          </div>





        </div>
      </div>

      <PropertyDetailsModal
        open={modalOpen}
        onClose={handleCloseModal}
        property={selectedProperty}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeAssignPhoneLead;
